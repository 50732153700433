.nav-links{
    font-size: 18px;
    color: #fff !important;
    font-weight: 600;
    padding: 8px 12px;
    width: 100%;
    text-align: center;
}
.metaModal .modal-content{
    background: rgb(0, 25 ,38) !important;
}
.metaModal .modal-header{
    border-bottom: 0;
}
.metaModal .modal-header .h4{
    Color: #fff !important;
    font-size: 24px;
    margin-bottom: 0;
}

 .metaModal .modal-body .walletLogo{
    width: 100px !important;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 24px;
    cursor: pointer;
}

.metaModal .modal-body .walletLogo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.metaModal {    
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
}