@import "./assets/css/main.css";

.w-4 {
	width: 4%;
}

.w-6 {
	width: 6%;
}

.w-12 {
	width: 12%;
	text-align: center;
}

.w-110 {
	width: 100px !important;
}

.w-13 {
	width: 13%;
}

.w-15 {
	width: 15%;
	text-align: center;
	cursor: default;
}

.w-20 {
	width: 20%;
	text-align: center;
	cursor: default;
}
.d-block-md {
	display: block;
}

.button-dead {
	color: white !important;
	background-color: red !important;
}

@media (max-width: 1200px) {
	.d-block-md {
		display: none;
	}
}

@media (max-width: 1200px) {
	.d-block-md {
		display: none;
	}
}

@media (min-width: 1600px) {
	.d-block-md {
		display: none;
	}
}
