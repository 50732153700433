
abbr,address,article,aside,audio,b,blockquote,body,canvas,caption,cite,code,dd,del,details,dfn,div,dl,dt,em,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,p,pre,q,samp,section,small,span,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,ul,var,video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    /* font-size: 100%; */
    vertical-align: initial;
    background: transparent
  }
  
  article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section {
    display: block
  }
  iframe{
    display: none !important;
  }
  html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important
  }
  
  body {
    line-height: 1
  }
  
  *,:after,:before {
    position: relative;
    box-sizing: inherit
  }
  
  embed,img,object {
    max-width: 100%;
    height: auto
  }
  
  blockquote,q {
    quotes: none
  }
  
  blockquote:after,blockquote:before,q:after,q:before {
    content: "";
    content: none
  }
  
  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: initial;
    background: transparent
  }
  
  del {
    text-decoration: line-through
  }
  
  abbr[title],dfn[title] {
    border-bottom: 1px dotted;
    cursor: help
  }
  
  em,i {
    font-style: italic
  }
  
  b,strong {
    font-weight: 700
  }
  
  q {
    quotes: "“" "”" "‘" "’"
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: inherit;
    font: 100%
  }
  
  th {
    font-weight: 700;
    vertical-align: bottom
  }
  
  td {
    font-weight: 400;
    vertical-align: top
  }
  
  input,select {
    vertical-align: middle
  }
  
  input[type=search] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box
  }
  
  input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration,input[type=search]::-webkit-search-results-button,input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none
  }
  
  fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
  }
  
  button,input,select,textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0
  }
  
  button {
    height: auto;
    padding: 0;
    background: transparent;
    outline: none;
    border: 0;
    border-radius: 0;
    cursor: pointer
  }
  
  sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative
  }
  
  sup {
    top: -.5em
  }
  
  sub {
    bottom: -.25em
  }
  
  mark {
    background: #ff0;
    color: #000
  }
  
  pre {
    white-space: pre;
    white-space: pre-wrap;
    white-space: pre-line;
    word-wrap: break-word
  }
  
  code,kbd,pre,samp {
    font-family: monospace,sans-serif
  }
  
  .bold,.strong,b,strong {
    font-weight: 700;
    font-family: "Montserrat",sans-serif
  }
  
  .em,em {
    font-style: italic
  }
  
  .txt-dec-none {
    text-decoration: none
  }
  
  body,html {
    color: #434343;
    font-size: 1rem
  }
  
  .form-section h2,body,html {
    font-family: "Montserrat",sans-serif
  }
  
  .tier-details .badge,.tier-details h2,.upper {
    text-transform: uppercase
  }
  
  .fw-black {
    font-weight: 900!important
  }
  
  .booster-box .nft-spinner .btn-minus,.booster-box .nft-spinner .btn-plus,.booster-box .nft-spinner .nft-number,.fw-bold,.main-podium-body .btn {
    font-weight: 700!important
  }
  
  .fw-semibold,.main-podium-body .form-control {
    font-weight: 600!important
  }
  
  .fw-medium,[data-balloon]:after {
    font-weight: 500!important
  }
  
  .fw-regular {
    font-weight: 400!important
  }
  
  a,a:active,a:link,a:visited {
    text-decoration: none
  }
  
  .text-primary {
    color: #302d75!important
  }
  
  .text-success {
    color: #05c31b!important
  }
  
  .text-info {
    color: #0ecff8!important
  }
  
  .text-warning {
    color: #fcba08!important
  }
  
  .text-danger {
    color: #f23a58!important
  }
  
  .text-lowercase {
    text-transform: lowercase!important
  }
  
  .text-uppercase {
    text-transform: uppercase!important
  }
  
  .text-capitalize {
    text-transform: capitalize!important
  }
  
  hr.space {
    border: none;
    height: 50px
  }
  
  .space {
    clear: both;
    width: 100%;
    height: 60px;
    display: block;
    position: relative
  }
  
  .space hr {
    clear: both;
    margin-top: 10px;
    margin-bottom: 10px
  }
  
  .hr hr {
    clear: both;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    border-top-color: #c7cfd8
  }
  
  address {
    line-height: 1.5;
    font-style: normal;
    margin-bottom: 1rem
  }
  
  abbr[data-original-title],abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
  }
  
  .h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    margin-bottom: .9375rem;
    font-family: "Montserrat",sans-serif;
    font-weight: 700!important
  }
  
  .h1 small,.h2 small,.h3 small,.h4 small,.h5 small,.h6 small,h1 small,h2 small,h3 small,h4 small,h5 small,h6 small {
    font-weight: 400;
    font-family: "Montserrat",sans-serif
  }
  
  .h1,h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    line-height: 1;
    font-weight: 700
  }
  
  @media (min-width: 768px) {
    .h1-sm,h1-sm {
        font-size:2.1875rem;
        line-height: 2.625rem;
        line-height: 1
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .h1-sm,h1-sm {
        font-size:2.5rem;
        line-height: 3rem;
        line-height: 1
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .h1-sm,h1-sm {
        font-size:2.875rem;
        line-height: 3.45rem;
        line-height: 1
    }
  }
  
  .h2,h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    line-height: 1.25;
    font-weight: 700
  }
  
  @media (min-width: 768px) {
    .h2-sm,h2-sm {
        font-size:2.125rem;
        line-height: 2.55rem;
        line-height: 1.25
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .h2-sm,h2-sm {
        font-size:2.3125rem;
        line-height: 2.775rem;
        line-height: 1
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .h2-sm,h2-sm {
        font-size:2.5rem;
        line-height: 3rem;
        line-height: 1
    }
  }
  
  .h3,h3 {
    font-size: 1.5625rem;
    line-height: 1.875rem;
    line-height: 1.1;
    font-weight: 700
  }
  
  @media (min-width: 768px) {
    .h3-sm,h3-sm {
        font-size:1.6875rem;
        line-height: 2.025rem;
        line-height: 1.25
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .h3-sm,h3-sm {
        font-size:1.875rem;
        line-height: 2.25rem;
        line-height: 1.1
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .h3-sm,h3-sm {
        font-size:2.1875rem;
        line-height: 2.625rem;
        line-height: 1.1
    }
  }
  
  .h4,h4 {
    font-size: 1.375rem;
    line-height: 1.65rem;
    line-height: 1.25;
    font-weight: 700
  }
  
  @media (min-width: 768px) {
    .h4-sm,h4-sm {
        font-size:1.375rem;
        line-height: 1.65rem;
        line-height: 1.25
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .h4-sm,h4-sm {
        font-size:1.5rem;
        line-height: 1.8rem;
        line-height: 1.25
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .h4-sm,h4-sm {
        font-size:1.625rem;
        line-height: 1.95rem;
        line-height: 1.25
    }
  }
  
  .h5,h5 {
    font-size: 1.1875rem;
    line-height: 1.425rem;
    line-height: 1.5
  }
  
  @media (min-width: 768px) {
    .h5-sm,h5-sm {
        font-size:1.25rem;
        line-height: 1.5rem;
        line-height: 1.5
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .h5-sm,h5-sm {
        font-size:1.25rem;
        line-height: 1.5rem;
        line-height: 1.5
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .h5-sm,h5-sm {
        font-size:1.375rem;
        line-height: 1.65rem;
        line-height: 1.5
    }
  }
  
  .text--red {
    color: #f23a58!important
  }
  
  .text--blue {
    color: #302d75!important
  }
  
  .text--grey {
    color: #434343!important
  }
  
  .text-white {
    color: #E8E8E8!important
  }
  
  .fs--13 {
    font-size: .8125rem;
    line-height: .975rem;
    line-height: 1.4
  }
  
  .fs--14 {
    font-size: .875rem;
    line-height: 1.05rem;
    line-height: 1.5
  }
  
  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    line-height: 1.5;
    margin-bottom: 1.25rem
  }
  
  p:last-child,p:last-of-type,p:only-child {
    margin-bottom: 0
  }
  
  @media (max-width: 767px) {
    p {
        font-size:1.125rem;
        line-height: 1.35rem;
        line-height: 1.5
    }
  }
  
  ol,ul {
    position: relative
  }
  
  ol li,ul li {
    font-size: 1.25rem;
    line-height: 1.5rem;
    line-height: 1.5;
    margin-bottom: .625rem
  }
  
  ol li a,ul li a {
    color: #302d75
  }
  
  ol li a:hover,ul li a:hover {
    text-decoration: none;
    transition: all .3s ease-in
  }
  
  ol li:last-child,ol li:last-of-type,ul li:last-child,ul li:last-of-type {
    margin-bottom: 0
  }
  
  @media (max-width: 767px) {
    ol li,ul li {
        font-size:1.125rem;
        line-height: 1.35rem;
        line-height: 1.5
    }
  }
  
  .marker-list li {
    list-style: square;
    list-style-position: inside
  }
  
  iframe {
    border: none
  }
  
  q {
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-style: italic;
    color: #000
  }
  
  .small,small {
    font-size: 75%;
    font-weight: 400;
    color: rgba(67,67,67,.75)
  }
  
  .heading-text {
    margin-bottom: 1.875rem
  }
  
  .heading-text .h1,.heading-text .h2,.heading-text .h3,.heading-text .h4,.heading-text h1,.heading-text h2,.heading-text h3,.heading-text h4 {
    font-size: 2.5rem;
    line-height: 3rem;
    line-height: 1.25;
    margin-bottom: 1.875rem
  }
  
  .heading-text .h1:before,.heading-text .h2:before,.heading-text .h3:before,.heading-text .h4:before,.heading-text h1:before,.heading-text h2:before,.heading-text h3:before,.heading-text h4:before {
    left: 0;
    right: 0;
    content: "";
    width: 30px;
    height: 3px;
    bottom: -10px;
    margin: 0 auto;
    position: absolute;
    background-color: rgba(89,88,89,.5)
  }
  
  @media (max-width: 767px) {
    .heading-text .h1,.heading-text .h2,.heading-text .h3,.heading-text .h4,.heading-text h1,.heading-text h2,.heading-text h3,.heading-text h4 {
        font-size:1.875rem;
        line-height: 2.25rem;
        line-height: 1.25
    }
  }
  
  body,html {
    min-height: 100%!important;
    overscroll-behavior-y: none!important
  }
  
  body {
    margin: 0;
    padding: 0;
    height: auto;
    color: #fff;
    overflow-x: hidden;
    background: #18181B none repeat scroll 0 0;
    font-size: 18px;
    font-family: "Montserrat",sans-serif!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
  }
  
  body,ol li,ul li {
    font-weight: 400
  }
  
  body {
    min-height: 100vh
  }
  
  body,html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%
  }
  
  body {
    color: #E8E8E8;
    display: -moz-flex;
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical;
    background-color: #18181B!important;
    height: 100%
  }
  
  svg:not(:root) {
    overflow: hidden
  }
  
  #root {
    display: -moz-flex;
    display: flex;
    min-height: 100%;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -moz-align-items: stretch;
    align-items: stretch
  }
  
  .full-page {
    flex: 1 1
  }
  
  .full-page-wrapper {
    width: 100%;
    color: #E8E8E8;
    -moz-flex: 1 0 auto;
    flex: 1 0 auto
  }
  
  @media (min-width: 1024px) {
    .full-page-wrapper {
        padding-top:5rem
    }
  }
  
  .full-page-wrapper:after {
    height: 0;
    font-size: 0;
    content: " ";
    display: block;
    visibility: hidden
  }
  
  .button {
    z-index: 1;
    outline: none;
    background: none;
    overflow: hidden;
    cursor: pointer;
    padding: .625rem 1.5625rem;
    display: inline-block;
    border: 2px solid transparent;
    font-size: .875rem;
    line-height: 1.05rem;
    line-height: 1.5;
    transition: all .3s ease;
    text-align: center;
    height: 40px;
    display: -moz-inline-flex;
    display: inline-flex;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center;
    font-family: "Montserrat",sans-serif
  }
  
  .button-block {
    display: -moz-flex;
    display: flex;
    width: 100%
  }
  
  .button-inline {
    display: -moz-inline-flex;
    display: inline-flex
  }
  
  .button-rounded {
    border-radius: 50px
  }
  
  .button-radius {
    border-radius: 4px
  }
  
  .button-xs {
    height: auto;
    font-size: .75rem;
    line-height: .9rem;
    padding: .3125rem .75rem;
    font-weight: 600
  }
  
  .button-sm {
    padding: .625rem 1.5625rem
  }
  
  .button-md,.button-sm {
    height: auto;
    font-size: .9375rem;
    line-height: 1.125rem
  }
  
  .button-md {
    padding: .625rem 1.875rem
  }
  
  .button-lg {
    height: auto;
    font-size: 1.125rem;
    line-height: 1.35rem;
    padding: 1rem 2.1875rem;
    font-weight: 700
  }
  
  @media (max-width: 767px) {
    .button-lg {
        font-size:1rem;
        line-height: 1.2rem;
        padding: .9375rem 1.5625rem
    }
  }
  
  .button-noborder {
    border: 0
  }
  
  .button:before {
    top: 0;
    width: 0;
    left: -25%;
    z-index: -1;
    content: "";
    height: 100%;
    position: absolute;
    -webkit-transform: skew(50deg);
    transform: skew(50deg);
    transition-duration: .6s;
    background-color: rgba(0,16,38,.2)
  }
  
  .button:hover:before {
    width: 180%
  }
  
  .button-blue {
    color: #fff;
    border-color: #302d75;
    background-color: #302d75
  }
  
  .button-blue:hover {
    color: #fff;
    border-color: #4742ac;
    background-color: #4742ac
  }
  
  .button-blue-bordered {
    color: #302d75;
    border-color: #999;
    background-color: #fff
  }
  
  .button-blue-bordered:hover {
    color: #fff;
    border-color: #302d75;
    background-color: #302d75
  }
  
  .button-blue2 {
    color: #fff;
    border-color: #27acde;
    background-color: #27acde
  }
  
  .button-blue2:hover {
    color: #fff;
    border-color: #69c6e8;
    background-color: #69c6e8
  }
  
  .button-blue2-bordered {
    color: #27acde;
    border-color: #999;
    background-color: #fff
  }
  
  .button-blue2-bordered:hover {
    color: #fff;
    border-color: #27acde;
    background-color: #27acde
  }
  
  .button-info {
    font-weight: 500;
    border: 0!important;
    color: white;
    background: linear-gradient(89.97deg, rgb(234, 137, 22) 0.03%, rgb(235, 106, 33) 94.43%);
  }
  
  .button-info:hover {
    color: #fff;

  }
  
  .button-info-bordered {
    color: #0ecff8;
    border-color: #0ecff8;
    background-color: #fff
  }
  
  .button-info-bordered:hover {
    color: white;
    background: linear-gradient(89.97deg, rgb(234, 137, 22) 0.03%, rgb(235, 106, 33) 94.43%);

  }
  
  .button-info:disabled {
    color: #18181B;
    opacity: .5;
    filter: alpha(opacity=50);
    background-color: #E8E8E8
  }
  
  .button-red {
    color: #fff;
    border-color: #f23a58;
    background-color: #f23a58
  }
  
  .button-red:hover {
    color: #fff;
    border-color: #ac001a;
    background-color: #ac001a
  }
  
  .button-red-bordered {
    color: #f23a58;
    border-color: #f23a58;
    background-color: #fff
  }
  
  .button-red-bordered:hover {
    color: #fff;
    border-color: #ac001a;
    background-color: #ac001a
  }
  
  .button-grey {
    color: #fff;
    border-color: #434343;
    background-color: #434343
  }
  
  .button-grey:hover {
    color: #fff;
    border-color: #303030;
    background-color: #303030
  }
  
  .button-grey-bordered {
    color: #434343;
    border-color: #434343;
    background-color: #fff
  }
  
  .button-grey-bordered:hover {
    color: #fff;
    border-color: #303030;
    background-color: #303030
  }
  
  .button-lightgrey {
    color: #18181B;
    border-color: #E8E8E8;
    background-color: #E8E8E8
  }
  
  .button-lightgrey:hover {
    color: #18181B;
    border-color: #aed0ff;
    background-color: #aed0ff
  }
  
  .button-lightgrey:hover:before {
    width: 180%
  }
  
  .button-primary {
    height: auto;
    border: 0;
    background-color: #E8E8E8
  }
  
  .button-boderwidth1 {
    border-width: 1px
  }
  
  .button+.c-button {
    margin-left: 10px
  }
  
  .button-disabled {
    opacity: .5;
    filter: alpha(opacity=50);
    pointer-events: none
  }
  
  .button-text {
    height: auto;
    border: 0;
    text-align: left;
    min-width: inherit;
    padding: 0;
    text-transform: capitalize;
    background-color: initial;
    transition: all .3s ease
  }
  
  .button-link-muted {
    font-size: .875rem;
    line-height: 1.05rem;
    line-height: 1.4
  }
  
  .button-link-muted,.button-link-muted .img_svg,.button-link-muted svg {
    color: #434343;
    transition: all .3s ease
  }
  
  .button-link-muted:active,.button-link-muted:focus,.button-link-muted:hover {
    color: #302d75;
    opacity: 1;
    filter: alpha(opacity=100)
  }
  
  .button-link-muted:active .img_svg,.button-link-muted:active svg,.button-link-muted:focus .img_svg,.button-link-muted:focus svg,.button-link-muted:hover .img_svg,.button-link-muted:hover svg {
    color: #302d75
  }
  
  .button:disabled {
    opacity: .5;
    filter: alpha(opacity=50);
    pointer-events: none
  }
  
  .button-transparent {
    cursor: pointer;
    border: 0;
    font-size: .9375rem;
    line-height: 1.125rem;
    background-color: initial;
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center
  }
  
  .button-transparent,.button-transparent .icon {
    color: #E8E8E8;
    opacity: 1;
    filter: alpha(opacity=100);
    text-align: center
  }
  
  .button-transparent .icon {
    width: 18px;
    height: 18px;
    font-size: .875rem;
    line-height: 1.05rem;
    line-height: 25px;
    margin-right: .3125rem
  }
  
  .button-transparent:last-child .icon {
    margin-left: .3125rem;
    margin-right: 0
  }
  
  .button-transparent:first-child .icon {
    margin-left: 0;
    margin-right: .3125rem
  }
  
  .button-transparent:active,.button-transparent:focus,.button-transparent:hover {
    color: #E8E8E8;
    opacity: 1;
    filter: alpha(opacity=100)
  }
  
  .button-transparent:active .icon,.button-transparent:focus .icon,.button-transparent:hover .icon {
    color: #E8E8E8
  }
  
  .button-transparent.selected .icon {
    color: #f23a58;
    opacity: 1;
    filter: alpha(opacity=100)
  }
  
  .btn.is-loading,.button.is-loading,button.is-loading {
    padding-right: 45px!important
  }
  
  .btn:not(.button-block).is-loading,.button:not(.button-block).is-loading,button:not(.button-block).is-loading {
    padding-right: 0!important
  }
  
  .button-xs.is-loading {
    min-width: 50px;
    max-width: 50px;
    cursor: default
  }
  
  .button-xs.is-loading:before {
    display: none
  }
  
  .is-loading {
    position: relative;
    transition: all .2s ease
  }
  
  .is-loading span {
    opacity: 0;
    filter: alpha(opacity=0)
  }
  
  .is-loading:after {
    top: 50%;
    right: 15px;
    opacity: 1;
    content: "";
    width: 18px;
    height: 18px;
    margin-top: -10px;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    border-top: 2px solid #18181B;
    border-right: 2px solid transparent;
    -webkit-animation: spinner .6s linear infinite;
    animation: spinner .6s linear infinite
  }
  
  .btn-success.is-loading:before {
    border-left-color: var(--color-green)
  }
  
  .btn-danger.is-loading:before {
    border-left-color: var(--color-error)
  }
  
  .btn-warning.is-loading:before {
    border-left-color: var(--color-warning)
  }
  
  .expiring-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px
  }
  
  @keyframes load-spin {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
  }
  
  @-webkit-keyframes load-spin {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
  }
  
  .footer-wrap {
    font-size: 14px;
    color: #E8E8E8;
    padding: 1.875rem 0 1.25rem;
    background-color: rgb(33, 34, 38);
    border-top: 1px solid #DD8837
  }
  
  @media (min-width: 768px) {
    .footer-wrap-sm {
        padding:2.1875rem 0 1.875rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .footer-wrap-sm {
        padding:2.5rem 0 1.875rem
    }
  }
  
  .footer-wrap .footer-top:before {
    left: 0;
    right: 0;
    top: 15px;
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    background-color: #DD8837
  }
  
  @media (max-width: 767px) {
    .footer-wrap .footer-top:before {
        top:13px
    }
  }
  
  .footer-wrap .footer-logo {
    width: 100%;
    max-width: 140px;
    padding-right: 30px;
    background-color: rgb(33, 34, 38)
  }
  
  @media (max-width: 767px) {
    .footer-wrap .footer-logo {
        top:3px;
        max-width: 120px;
        padding-right: 15px
    }
  }
  
  .footer-wrap .footer-social {
    padding-left: 30px;
    background-color: rgb(33, 34, 38)
  }
  
  .footer-wrap .footer-social-lists {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center
  }
  
  .footer-wrap .footer-social-item {
    padding: 0;
    list-style: none;
    margin: 0 0 0 .625rem
  }
  
  .footer-wrap .footer-social-item:first-child {
    margin-left: 0
  }
  
  .footer-wrap .footer-social-item a {
    font-size: 0;
    display: -moz-flex;
    display: flex;
    width: 32px;
    height: 32px;
    padding: .375rem;
    border-radius: 50%;
    -moz-align-items: center;
    align-items: center;
    background-color: #E8E8E8
  }
  
  .footer-wrap .footer-social-item a:active,.footer-wrap .footer-social-item a:focus,.footer-wrap .footer-social-item a:hover {
    background-color: rgb(234, 137, 22)
  }
  
  @media (max-width: 767px) {
    .footer-wrap .footer-social {
        padding-left:15px
    }
  
    .footer-wrap .footer-social .footer-social-item a {
        width: 26px;
        height: 26px
    }
  }
  
  .footer-wrap .footer-content p {
    font-size: 14px;
    line-height: 1.6
  }
  
  @media (max-width: 992px) {
    .footer-wrap .footer-content {
        max-width:80%;
        text-align: center;
        margin: 0 auto
    }
  }
  
  .footer-wrap .footer-links {
    margin-top: 20px
  }
  
  .footer-wrap .footer-links-item,.footer-wrap .footer-links-lists {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -moz-flex;
    display: flex;
    -moz-justify-content: flex-end;
    justify-content: flex-end
  }
  
  .footer-wrap .footer-links-item:first-child,.footer-wrap .footer-links-lists:first-child {
    margin-top: 0
  }
  
  @media (max-width: 992px) {
    .footer-wrap .footer-links-item,.footer-wrap .footer-links-lists {
        -moz-justify-content:center;
        justify-content: center
    }
  }
  
  .footer-wrap .footer-links-item+.footer-links-item {
    margin-left: 25px
  }
  
  .footer-wrap .footer-links-lists {
    padding-right: 104px
  }
  
  @media (max-width: 992px) {
    .footer-wrap .footer-links-lists {
        padding-right:0
    }
  }
  
  @media (max-width: 480px) {
    .footer-wrap .footer-links-lists {
        -moz-flex-direction:column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  
    .footer-wrap .footer-links-lists .footer-links-item {
        margin-top: 15px;
        margin-left: 0
    }
  }
  
  .footer-wrap .footer-links a {
    font-size: 14px;
    color: #E8E8E8;
    position: relative
  }
  
  .footer-wrap .footer-links a:before {
    left: 0;
    width: 0;
    height: 1px;
    content: " ";
    bottom: -2px;
    position: absolute;
    background-color: #E8E8E8
  }
  
  .footer-wrap .footer-links a:active,.footer-wrap .footer-links a:focus,.footer-wrap .footer-links a:hover {
    color: rgb(234, 137, 22)
  }
  
  .footer-wrap .footer-links a:active:before,.footer-wrap .footer-links a:focus:before,.footer-wrap .footer-links a:hover:before {
    width: 100%;
    background-color: rgb(234, 137, 22)
  }
  
  .required {
    color: #f23a58
  }
  
  .w-20 {
    width: 20%!important
  }
  
  .grecaptcha-text a {
    color: #218cc8;
    border-bottom: 1px solid #218cc8;
    transition: all .3s ease-in
  }
  
  .grecaptcha-text a:hover {
    border-bottom: 1px solid transparent
  }
  
  .grecaptcha-badge {
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0)
  }
  
  .form-group {
    margin-bottom: 1.25rem
  }
  
  .form-group label,.form-group legend {
    color: #000;
    cursor: pointer;
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-weight: 500;
    margin-bottom: .4375rem
  }
  
  .form-group label.leftAlign,.form-group label.rightAlign,.form-group legend.leftAlign,.form-group legend.rightAlign {
    width: 100%;
    text-align: left
  }
  
  .form-group .form-control {
    color: #000;
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.35rem;
    padding: .8125rem .9375rem;
    border: 1px solid #c7cfd8;
    border-radius: 4px;
    font-weight: 400;
    transition: all .3s ease-out;
    -ms-box-shadow: 0 2px 3px rgba(0,0,0,.05);
    box-shadow: 0 2px 3px rgba(0,0,0,.05)
  }
  
  .form-group .form-control:focus {
    border: 1px solid #302d75!important;
    box-shadow: 0 0 0 3px rgba(48,45,117,.25)!important
  }
  
  .form-group .form-control.medium {
    padding: .625rem .9375rem
  }
  
  .form-group textarea.form-control {
    resize: none;
    height: 120px
  }
  
  .form-group .help-block {
    color: #fff;
    border-radius: 5px;
    display: -moz-inline-flex;
    display: inline-flex;
    padding: .125rem .375rem;
    font-size: .875rem;
    line-height: 1.05rem;
    margin-top: .3125rem;
    background-color: #f23a58
  }
  
  .form-group .selectdropdown,.form-group .selectdropdown select {
    height: 42px;
    position: relative;
    width: 100%
  }
  
  .form-group .selectdropdown select {
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    padding: 0 .625rem;
    border: 1px solid #f7f8f9;
    z-index: 3
  }
  
  .form-group .selectdropdown select:focus {
    outline: none;
    border: 1px solid #302d75
  }
  
  .form-group .selectdropdown select option {
    color: #000;
    font-size: .9375rem;
    line-height: 1.125rem;
    padding: .875rem 0;
    font-weight: 400;
    border-bottom: 1px solid #f7f8f9!important
  }
  
  .form-group .selectdropdown .form-control {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1.875rem
  }
  
  .form-group .selectdropdown:after {
    z-index: 2;
    top: 52.5%;
    right: 14px;
    display: block;
    content: "\f078";
    position: absolute;
    pointer-events: none;
    color: rgba(67,67,67,.5);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  
  .form-group .selectdropdown--withchosen .chosen-container {
    z-index: 3;
    border: 1px solid #f7f8f9;
    width: 100%!important
  }
  
  .form-group .selectdropdown--withchosen .chosen-container.chosen-container-active {
    border: 1px solid #302d75
  }
  
  .form-group .selectdropdown--withchosen .chosen-single div {
    display: none
  }
  
  .form-group .selectdropdown--withchosen .chosen-container-active.chosen-with-drop .chosen-single,.form-group .selectdropdown--withchosen .chosen-single {
    background: transparent;
    border: none;
    padding-top: .625rem;
    height: 42px;
    border-radius: 0;
    -ms-box-shadow: none;
    box-shadow: none
  }
  
  .form-group .selectdropdown--withchosen .chosen-container .chosen-drop {
    margin-top: 1px;
    margin-left: -1px;
    margin-right: -1px;
    width: calc(100% + 2px);
    -ms-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #f7f8f9;
    border-top: 0
  }
  
  .form-group .selectdropdown--withchosen .chosen-container .chosen-results {
    overflow: auto;
    max-height: 160px
  }
  
  .form-group .selectdropdown--withchosen .chosen-container .chosen-results li {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;
    color: #434343
  }
  
  .form-group .selectdropdown--withchosen .chosen-container-single .chosen-single span {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;
    color: #000
  }
  
  .form-group .selectdropdown--withchosen .chosen-container .chosen-results li {
    border-top: 1px solid #f7f8f9;
    padding: .625rem 0
  }
  
  .form-group .selectdropdown--withchosen .chosen-container .chosen-results li:first-child {
    border-top: none
  }
  
  .form-group .selectdropdown--withchosen .chosen-container .chosen-results li.highlighted {
    color: #218cc8;
    background: #fff
  }
  
  .form-group--has-error label {
    color: #f23a58
  }
  
  .form-group--has-error .form-control {
    border: 1px solid #f23a58;
    box-shadow: 0 0 0 3px rgba(242,58,88,.1)!important
  }
  
  .form-group--has-error .form-control:focus {
    border: 1px solid #f23a58!important;
    box-shadow: 0 0 0 3px rgba(242,58,88,.25)!important
  }
  
  .form-group--has-error .help-block {
    color: #fff;
    display: inline-block
  }
  
  .form-group--has-success label {
    color: #05c31b
  }
  
  .form-group--has-success .form-control {
    border: 1px solid #05c31b;
    box-shadow: 0 0 0 3px rgba(5,195,27,.1)!important
  }
  
  .form-group--has-success .form-control:focus {
    border: 1px solid #05c31b!important;
    box-shadow: 0 0 0 3px rgba(5,195,27,.25)!important
  }
  
  .form-group--has-success .help-block {
    color: #05c31b
  }
  
  .form-group--mb7 {
    margin-bottom: .4375rem
  }
  
  .form-group .textareasize--smarll {
    height: 120px
  }
  
  .form-group .textareasize--medium {
    height: 160px
  }
  
  .form-group .textareasize--large {
    height: 220px
  }
  
  .form-group .uploadfilebox {
    min-width: 300px
  }
  
  .form-group .uploadfilebox a {
    display: block;
    padding: .75rem;
    font-size: .75rem;
    line-height: .9rem;
    font-weight: 700;
    text-align: center;
    transition: all .3s ease-out;
    -ms-box-shadow: inset 0 0 1px rgba(89,88,89,.5);
    box-shadow: inset 0 0 1px rgba(89,88,89,.5)
  }
  
  .form-group .uploadfilebox--block {
    width: 100%
  }
  
  .form-group .uploadfilebox--bgwhitered a {
    background: #fff;
    color: #f23a58;
    border: 1px solid #f7f8f9
  }
  
  .form-group .uploadfilebox--bgwhitered a:focus,.form-group .uploadfilebox--bgwhitered a:hover {
    background: #f23a58;
    color: #fff;
    border-color: #f23a58
  }
  
  .form-group fieldset {
    margin: 0;
    padding: 0;
    border: 0
  }
  
  .form-group fieldset,.form-group legend {
    border: 0
  }
  
  .panel-body .form-group:last-child,.panel-body .form-group:last-of-type,.panel-body .form-group:only-child,.panel-body .form-group:only-of-type {
    margin-bottom: .25rem
  }
  
  .chosen-container-single .chosen-search input[type=text] {
    color: #000;
    min-height: 34px;
    font-size: 1rem;
    line-height: 1.2rem;
    font-family: "Montserrat",sans-serif;
    font-weight: 400
  }
  
  .form-control::-webkit-input-placeholder {
    color: rgba(67,67,67,.5)
  }
  
  .form-control::-moz-placeholder {
    color: rgba(67,67,67,.5)
  }
  
  .form-control::-ms-input-placeholder {
    color: rgba(67,67,67,.5)
  }
  
  .form-control::-o-placeholder {
    color: rgba(67,67,67,.5)
  }
  
  .form-wrap.text-center label.error {
    display: table;
    margin-top: 5px;
    text-align: left!important
  }
  
  .button-set {
    width: 100%;
    position: relative;
    margin-bottom: 0;
    display: -moz-flex;
    display: flex
  }
  
  .button-set [class^=column] {
    margin-right: 50px;
    display: inline-block
  }
  
  @media screen and (max-width: 480px) {
    .button-set [class^=column] {
        display:block;
        margin-right: 0
    }
  }
  
  .button-set label {
    width: 100%;
    color: #000;
    cursor: pointer;
    margin-right: 0;
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.35rem;
    line-height: 1.25;
    padding-left: 1.875rem;
    font-weight: 400
  }
  
  .button-set input[type=checkbox],.button-set input[type=radio] {
    margin: 0;
    height: 1px;
    padding: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    pointer-events: none
  }
  
  .button-set input[type=radio]+span {
    width: 20px;
    height: 20px;
    text-align: center;
    display: inline-block;
    background-color: #fff;
    border: 2px solid #c7cfd8;
    vertical-align: top;
    position: relative;
    margin-right: .625rem;
    transition: all .1s ease;
    top: 2px
  }
  
  .button-set input[type=radio]+span:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    display: inline-block;
    transition: all .1s ease
  }
  
  .button-set input[type=radio]:active+span,.button-set input[type=radio]:focus+span {
    border-color: #05c31b
  }
  
  .button-set input[type=radio]:checked+span:after {
    opacity: 1;
    filter: alpha(opacity=100)
  }
  
  .button-set input[type=radio].error+span {
    border-color: #f23a58
  }
  
  .button-set input[type=radio]+span {
    border-radius: 50%
  }
  
  .button-set input[type=radio]+span:after {
    border-radius: 4px;
    background-color: #302d75;
    border-color: #302d75
  }
  
  .button-set input[type=radio]:checked+span {
    border: 2px solid #302d75;
    transition: all .1s ease
  }
  
  .button-set input[type=radio]:checked+span:after {
    border: 3px solid #fff;
    border-radius: 50%
  }
  
  form.contact-form .help-block-error {
    display: block
  }
  
  .title .button-set label {
    color: #000
  }
  
  .title .button-set input[type=checkbox]+span,.title .button-set input[type=radio]+span {
    border: 0
  }
  
  .button-set .eds-checked {
    top: 0;
    left: 0;
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    padding-right: .3125rem
  }
  
  .button-set .eds-checked:before {
    top: 0;
    left: 0;
    margin: 0;
    z-index: 1;
    content: "";
    display: block;
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: rgba(217,233,255,0);
    -webkit-transform: scale3d(2.3,2.3,1);
    transform: scale3d(2.3,2.3,1)
  }
  
  .button-set .eds-checked .check {
    z-index: 1;
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #E8E8E8;
    border-radius: 4px;
    -ms-box-shadow: 0 2px 3px rgba(0,0,0,.05);
    box-shadow: 0 2px 3px rgba(0,0,0,.05)
  }
  
  .button-set .eds-checked .check:before {
    content: "";
    display: block;
    margin-top: 0;
    margin-left: 10px;
    position: absolute;
    width: 0;
    height: 0;
    background: #302d75!important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: checkbox-off .3s forwards;
    animation: checkbox-off .3s forwards
  }
  
  .button-set input[type=checkbox]:focus+.eds-checked .check:after {
    opacity: .2;
    filter: alpha(opacity=20)
  }
  
  .button-set input[type=checkbox]:checked+.eds-checked .check {
    background: #18181B!important;
    border-color: #27255f
  }
  
  .button-set input[type=checkbox]:checked+.eds-checked .check:before {
    color: #E8E8E8;
    background: #18181B!important;
    -webkit-animation: checkbox-on .3s forwards;
    animation: checkbox-on .3s forwards;
    -ms-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px;
    -o-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px;
    box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px
  }
  
  .button-set input[type=checkbox][disabled]~.eds-checked .check {
    border-color: #302d75;
    opacity: .26;
    filter: alpha(opacity=26)
  }
  
  .button-set input[type=checkbox][disabled]+.eds-checked .check:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #302d75!important
  }
  
  .button-set input[type=checkbox]:checked+.eds-checked:before,.button-set input[type=radio]:checked+.eds-checked:before {
    -webkit-animation: rippleOn .5s;
    animation: rippleOn .5s;
    background-color: rgba(39,172,222,.75)
  }
  
  .button-set input[type=checkbox]:checked+.eds-checked .check:after,.button-set input[type=radio]:checked+.eds-checked span:after {
    -webkit-animation: rippleOn .5s forwards;
    animation: rippleOn .5s forwards
  }
  
  .button-set input[type=checkbox]:not(:checked)+.eds-checked:before,.button-set input[type=radio]:not(:checked)+.eds-checked:before {
    -webkit-animation: rippleOff .5s;
    animation: rippleOff .5s;
    background-color: rgba(48,45,117,.3)
  }
  
  @-webkit-keyframes checkbox-on {
    0% {
        -ms-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,15px 2px 0 11px;
        -o-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,15px 2px 0 11px;
        box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,15px 2px 0 11px
    }
  
    50% {
        -ms-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px 2px 0 11px;
        -o-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px 2px 0 11px;
        box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px 2px 0 11px
    }
  
    to {
        -ms-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px;
        -o-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px;
        box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px
    }
  }
  
  @keyframes checkbox-on {
    0% {
        -ms-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,15px 2px 0 11px;
        -o-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,15px 2px 0 11px;
        box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,15px 2px 0 11px
    }
  
    50% {
        -ms-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px 2px 0 11px;
        -o-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px 2px 0 11px;
        box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px 2px 0 11px
    }
  
    to {
        -ms-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px;
        -o-box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px;
        box-shadow: 0 0 0 10px,10px -10px 0 10px,32px 0 0 20px,0 32px 0 20px,-5px 5px 0 10px,20px -12px 0 11px
    }
  }
  
  @-webkit-keyframes rippleOn {
    0% {
        opacity: 0;
        filter: alpha(opacity=0)
    }
  
    50% {
        opacity: .2;
        filter: alpha(opacity=20)
    }
  
    to {
        opacity: 0;
        filter: alpha(opacity=0)
    }
  }
  
  @keyframes rippleOn {
    0% {
        opacity: 0;
        filter: alpha(opacity=0)
    }
  
    50% {
        opacity: .2;
        filter: alpha(opacity=20)
    }
  
    to {
        opacity: 0;
        filter: alpha(opacity=0)
    }
  }
  
  @-webkit-keyframes rippleOff {
    0% {
        opacity: 0;
        filter: alpha(opacity=0)
    }
  
    50% {
        opacity: .2;
        filter: alpha(opacity=20)
    }
  
    to {
        opacity: 0;
        filter: alpha(opacity=0)
    }
  }
  
  @keyframes rippleOff {
    0% {
        opacity: 0;
        filter: alpha(opacity=0)
    }
  
    50% {
        opacity: .2;
        filter: alpha(opacity=20)
    }
  
    to {
        opacity: 0;
        filter: alpha(opacity=0)
    }
  }
  
  .radio {
    height: auto;
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center
  }
  
  .radio.column {
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical
  }
  
  .radio label {
    color: #000;
    height: auto;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0;
    position: relative;
    padding-left: 1.625rem;
    display: inline-block;
    font-size: 1.125rem;
    line-height: 1.35rem;
    line-height: 1.5;
    font-weight: 500;
    text-transform: inherit!important;
    max-width: 100%;
    -moz-flex: 0 0 100%;
    flex: 0 0 100%
  }
  
  .radio label strong {
    color: #333;
    padding-left: .25rem
  }
  
  .radio label a,.radio label span.text-green {
    padding-left: .25rem
  }
  
  .radio label:before {
    left: 0;
    top: .25rem;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #c7cfd8;
    transition: border .5s ease-in-out;
    width: 20px;
    height: 20px;
    outline: 0!important
  }
  
  .radio label:after,.radio label:before {
    content: " ";
    display: inline-block;
    margin-left: 0;
    position: absolute
  }
  
  .radio label:after {
    left: 4px;
    top: .5rem;
    -moz-transition: -moz-transform .1s cubic-bezier(.8,-.33,.2,1.33);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -o-transition: -o-transform .1s cubic-bezier(.8,-.33,.2,1.33);
    -webkit-transform: scale(0);
    -webkit-transition: -webkit-transform .1s cubic-bezier(.8,-.33,.2,1.33);
    background-color: #6c757d;
    border-radius: 50%;
    transform: scale(0);
    transition: -webkit-transform .1s cubic-bezier(.8,-.33,.2,1.33);
    transition: transform .1s cubic-bezier(.8,-.33,.2,1.33);
    transition: transform .1s cubic-bezier(.8,-.33,.2,1.33),-webkit-transform .1s cubic-bezier(.8,-.33,.2,1.33);
    width: 12px;
    height: 12px
  }
  
  .radio input[type=radio] {
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    outline: 0!important;
    position: absolute;
    width: 1px;
    height: 1px
  }
  
  .radio input[type=radio]:disabled+label {
    opacity: .65;
    filter: alpha(opacity=65)
  }
  
  .radio input[type=radio]:focus+label:before {
    outline-offset: -2px;
    outline: 5px auto -webkit-focus-ring-color;
    outline: thin dotted
  }
  
  .radio input[type=radio]:checked+label:after {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  
  .radio input[type=radio]:disabled+label:before {
    cursor: not-allowed
  }
  
  .radio-right label {
    padding-left: 0;
    padding-right: 26px
  }
  
  .radio-right label:before {
    top: 1px;
    right: 0;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    transition: border .5s ease-in-out;
    width: 18px;
    outline: 0!important
  }
  
  .radio-right label:after {
    top: 5px;
    left: auto;
    right: 4px;
    margin-left: 0;
    margin-right: 0
  }
  
  .radio-right input[type=radio] {
    position: absolute
  }
  
  .radio.radio-inline {
    margin-top: 0
  }
  
  .radio.radio-single label {
    height: 17px
  }
  
  .radio-primary input[type=radio]+label:before {
    border-color: #c7cfd8
  }
  
  .radio-primary input[type=radio]+label:after {
    background-color: #302d75
  }
  
  .radio-primary input[type=radio]:checked+label,.radio-primary input[type=radio]:checked+label strong {
    color: #302d75
  }
  
  .radio-primary input[type=radio]:checked+label:before {
    border-color: #302d75
  }
  
  .radio-primary input[type=radio]:checked+label:after {
    background-color: #302d75
  }
  
  .vertical-form .form-group {
    width: 100%;
    margin-bottom: 15px
  }
  
  .vertical-form .form-group.no-margin-bottom {
    margin-bottom: 0
  }
  
  .vertical-form .form-group input[type=email],.vertical-form .form-group input[type=number],.vertical-form .form-group input[type=password],.vertical-form .form-group input[type=search],.vertical-form .form-group input[type=tel],.vertical-form .form-group input[type=text],.vertical-form .form-group input[type=url],.vertical-form .form-group select,.vertical-form .form-group textarea {
    display: block;
    width: 100%;
    padding: .4375rem 0;
    outline: none;
    font-size: .8125rem;
    line-height: .975rem;
    line-height: 1.54;
    font-weight: 500;
    color: #434343;
    height: auto;
    text-align: left;
    font-family: "Montserrat",sans-serif;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #f7f8f9;
    transition: all .3s ease-out;
    box-shadow: none
  }
  
  .vertical-form .form-group .input--type {
    position: relative
  }
  
  .vertical-form .form-group .input--type--icon {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(89,88,89,.7)
  }
  
  .vertical-form .form-group .forgot--password--link {
    color: #434343;
    font-family: 400;
    font-size: .6875rem;
    line-height: .825rem;
    line-height: normal;
    margin-bottom: .625rem;
    letter-spacing: normal;
    font-family: "Montserrat",sans-serif;
    display: block;
    font-style: italic;
    opacity: .8;
    text-decoration: underline
  }
  
  .vertical-form .form-group .custom-select {
    -webkit-appearance: none!important;
    appearance: none!important
  }
  
  .vertical-form .form-group .custom-select:after {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 15px;
    top: 5px;
    display: inline-block
  }
  
  .vertical-form .o-modal--action {
    margin-top: 1.875rem
  }
  
  .vertical-form .o-modal--subaction {
    margin-top: .625rem
  }
  
  .vertical-form .help-block {
    display: none
  }
  
  .vertical-form .has-success .input--type--icon {
    color: #302d75
  }
  
  .vertical-form .has-error .input--type--icon {
    color: #f23a58
  }
  
  .c-formrequirement {
    font-size: .75rem;
    line-height: .9rem;
    color: #434343
  }
  
  .c-formrequirement__head {
    line-height: 1.5;
    font-weight: 700
  }
  
  .c-formrequirement__list li {
    font-weight: 400;
    font-style: italic;
    line-height: 1.5
  }
  
  .datepicker.datepicker-dropdown.dropdown-menu {
    padding: .375rem;
    font-size: .875rem;
    line-height: 1.05rem;
    font-family: "Montserrat",sans-serif;
    -ms-box-shadow: none;
    box-shadow: none
  }
  
  .datepicker table tr td {
    height: 27px
  }
  
  .datepicker table tr td,.datepicker table tr th {
    vertical-align: middle;
    font-family: "Montserrat",sans-serif
  }
  
  .datepicker table tr td.active,.datepicker table tr td.active.active,.datepicker table tr th.active,.datepicker table tr th.active.active {
    color: #fff;
    border-color: #302d75;
    background-color: #302d75
  }
  
  .daterangepicker {
    border-color: #c7cfd8;
    margin-top: -.0625rem;
    font-family: "Montserrat",sans-serif;
    border-radius: 4px
  }
  
  .daterangepicker td.active,.daterangepicker td.active:hover {
    color: #fff;
    background-color: #302d75
  }
  
  .daterangepicker td.in-range {
    background-color: #000013
  }
  
  .daterangepicker td.active.end-date.available {
    background-color: #302d75
  }
  
  .daterangepicker td.active.available:hover {
    color: #fff;
    background-color: #302d75
  }
  
  .daterangepicker .calendar-table td {
    height: 26px;
    font-size: .8125rem;
    line-height: .975rem;
    line-height: 26px;
    font-family: "Montserrat",sans-serif
  }
  
  .daterangepicker .applyBtn,.daterangepicker .applyBtn:hover {
    color: #fff;
    border-color: #302d75;
    background-color: #302d75
  }
  
  .c-formnotice {
    font-size: .9375rem;
    line-height: 1.125rem;
    color: #434343;
    line-height: 1.47
  }
  
  .c-formnotice--medium {
    font-weight: 500
  }
  
  .office-form-matrix-group .office-form-matrix-header,.office-form-matrix-group .office-form-matrix-row {
    display: -moz-flex;
    display: flex;
    padding: 0
  }
  
  .office-form-matrix-group .office-form-matrix-header .office-form-matrix-cell,.office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell {
    outline: none;
    display: -moz-flex;
    display: flex;
    word-break: break-word;
    padding: .625rem 0 .9375rem;
    overflow-wrap: break-word;
    -moz-align-items: flex-end;
    align-items: flex-end;
    color: #000;
    cursor: pointer;
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-weight: 500
  }
  
  .office-form-matrix-group .office-form-matrix-header .office-form-matrix-cell:first-child,.office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell:first-child {
    width: 100%;
    max-width: 250px;
    padding: .9375rem
  }
  
  .office-form-matrix-group .office-form-matrix-header .office-form-matrix-cell:not(:first-child),.office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell:not(:first-child) {
    -moz-flex: 1;
    flex: 1 1;
    text-align: center;
    -moz-justify-content: center;
    justify-content: center
  }
  
  .office-form-matrix-group .office-form-matrix-row {
    background-color: #f7f8f9;
    -moz-align-items: center;
    align-items: center
  }
  
  .office-form-matrix-group .office-form-matrix-row:nth-child(odd) {
    background-color: #e8ebee
  }
  
  .office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell:not(:first-child) {
    cursor: pointer;
    -moz-flex: 1;
    flex: 1 1;
    text-align: center;
    -moz-justify-content: center;
    justify-content: center
  }
  
  .office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell .radio {
    top: -13px
  }
  
  .office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell .button-set label {
    padding-left: 0
  }
  
  .office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell .button-set input[type=radio]+span {
    margin-right: 0;
    border-color: #b8c2ce
  }
  
  .office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell .button-set input[type=radio]:checked+span {
    border-color: #302d75
  }
  
  .office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell input[type=radio] {
    cursor: pointer;
    width: 24px;
    height: 24px
  }
  
  .office-form-matrix-group .office-form-matrix-header {
    background: #fff
  }
  
  @media (max-width: 767px) {
    .office-form-matrix-group {
        display:-moz-flex;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -moz-flex-direction: column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  
    .office-form-matrix-group .office-form-matrix-header,.office-form-matrix-group .office-form-matrix-row {
        position: relative
    }
  
    .office-form-matrix-group .office-form-matrix-header .office-form-matrix-cell,.office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell {
        width: 150px!important;
        min-width: 150px!important;
        white-space: normal!important
    }
  
    .office-form-matrix-group .office-form-matrix-header .office-form-matrix-cell:first-child,.office-form-matrix-group .office-form-matrix-row .office-form-matrix-cell:first-child {
        width: 200px!important;
        min-width: 200px!important;
        white-space: normal!important
    }
  
    .office-form-matrix-group .office-form-matrix-row:before {
        top: 0;
        left: -200%;
        right: -200%;
        content: " ";
        height: 100%;
        position: absolute;
        background-color: #f7f8f9
    }
  
    .office-form-matrix-group .office-form-matrix-row:nth-child(odd):before {
        background-color: #e8ebee
    }
  }
  
  @media (max-width: 767px) {
    .form-group [class^=col-]:first-child,.form-group [class^=col-]:first-of-type {
        margin-bottom:1.25rem
    }
  
    .form-group [class^=col-]:last-child,.form-group [class^=col-]:last-of-type,.form-group [class^=col-]:only-child,.form-group [class^=col-]:only-of-type {
        margin-bottom: 0
    }
  }
  
  .modal-backdrop.show {
    opacity: .75;
    filter: alpha(opacity=75);
    opacity: .75!important
  }
  
  .modal-dialog-centered {
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center
  }
  
  @media (max-width: 767px) {
    .modal-dialog {
        margin:.75rem 1.25rem!important
    }
  }
  
  .modal-content {
    padding: .125rem;
    border: 0!important;
    border-radius: 1.25rem!important;
    background: linear-gradient(90deg,#27acde,#E8E8E8)
  }
  
  .modal-content-wrapper {
    background-color: rgb(33, 34, 38);
    border-radius: 1.25rem!important
  }
  
  .modal-footer,.modal-header {
    border: 0!important
  }
  
  .modal-header {
    color: #fff;
    position: relative;
    padding: 0!important
  }
  
  .modal-header .modal-title {
    color: #18181B;
    font-size: 24px;
    padding-top: 0;
    padding-left: 30px;
    font-weight: 900
  }
  
  @media (max-width: 767px) {
    .modal-header .modal-title {
        font-size:18px;
        padding-top: 10px;
        padding-left: 15px
    }
  }
  
  .modal-header .btn-close {
    margin: 0!important;
    outline: none!important;
    box-shadow: none!important;
    padding: 1.5rem!important;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat
  }
  
  .modal-header.has-bg {
    border-radius: 1.125rem 1.125rem 0 0!important
  }
  
  .modal-header.has-bg,.modal-header.has-bg:before {
    background: linear-gradient(90deg,#27acde,#E8E8E8)
  }
  
  .modal-header.has-bg:before {
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    content: " ";
    bottom: -2px;
    position: absolute
  }
  
  .modal-header.has-bg .btn-close {
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23001026'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat
  }
  
  .modal-body {
    color: #E8E8E8;
    padding: 20px 30px 40px!important
  }
  
  @media (max-width: 767px) {
    .modal-body {
        padding:15px 15px 20px!important
    }
  }
  
  .modal-body h3 {
    font-size: 1.5rem;
    font-weight: 900!important
  }
  
  .modal-body .card-small {
    border-radius: 0;
    background-color: initial
  }
  
  .modal-body .card-small .form-control {
    background-color: #18181B;
    border-radius: .5rem!important
  }
  
  .modal-body .card-small .form-control,.modal-body .card-small button {
    height: 52px
  }
  
  @media (min-width: 768px) {
    .modal-dialog {
        margin-top:0!important
    }
  }
  
  body.modal-open {
    overflow: hidden!important
  }
  
  body.modal-open #root .full-page-wrapper {
    -webkit-filter: blur(12px);
    -moz-filter: blur(12px);
    -o-filter: blur(12px);
    filter: blur(12px)
  }
  
  @-webkit-keyframes spinner {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
  }
  
  @keyframes spinner {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
  }
  
  .spinner2:before,.spinner3:before,.spinner4:before,.spinner5:before,.spinner:before {
    top: 50%;
    left: 50%;
    content: "";
    margin-top: -25px;
    margin-left: -25px;
    position: absolute;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 50%
  }
  
  .spinner:before {
    border: 2px solid #fff;
    border-top-color: #000;
    -webkit-animation: spinner .6s linear infinite;
    animation: spinner .6s linear infinite
  }
  
  .spinner2:before {
    border: 1px solid #fff;
    border-top-color: #0ecff8;
    -webkit-animation: spinner .5s linear infinite;
    animation: spinner .5s linear infinite
  }
  
  .spinner3:before {
    border-color: #0ecff8 transparent;
    border-style: solid;
    border-width: 3px;
    -webkit-animation: spinner .8s ease infinite;
    animation: spinner .8s ease infinite
  }
  
  .spinner4:before {
    border-top: 2px solid #0ecff8;
    border-right: 2px solid transparent;
    -webkit-animation: spinner .6s linear infinite;
    animation: spinner .6s linear infinite
  }
  
  .spinner5:before {
    border-color: #05c31b #0ecff8 #fcba08 #f23a58;
    border-style: solid;
    border-width: 2px;
    -webkit-animation: spinner .5s linear infinite;
    animation: spinner .5s linear infinite
  }
  
  .size-14 .spinner2:before,.size-14 .spinner3:before,.size-14 .spinner4:before,.size-14 .spinner5:before,.size-14 .spinner:before {
    margin-top: -7px;
    margin-left: -7px;
    width: 14px;
    height: 14px
  }
  
  .size-14 .spinner4:before {
    border-top: 1.5px solid #fff;
    border-right: 1.5px solid transparent
  }
  
  .unclaimed-bonus .button .spinner4:before,.w-30 .spinner4:before,.wallet-connect .spinner4:before,.webapp .spinner4:before {
    border-top: 2px solid rgb(33, 34, 38)
  }
  
  .size-20,.size-20 .spinner4 {
    width: 24px;
    height: 24px
  }
  
  .size-20 .spinner4:before {
    margin-top: -12px;
    margin-left: -12px;
    position: absolute;
    width: 24px;
    height: 24px;
    border-top: 2px solid #E8E8E8
  }
  
  .header {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: absolute;
    padding: .9375rem;
    z-index: 12!important;
    background-color: #18181B;
    transition: all .15s ease-out;
    border-bottom: 1px solid #DD8837
  }
  
  @media (min-width: 768px) {
    .header-sm {
        padding:.9375rem 0
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .header-sm {
        padding:1rem 0
    }
  }
  
  .header.is-sticky {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: fixed;
    -ms-box-shadow: 0 10px 20px 0 rgba(0,0,0,.35);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.35)
  }
  
  @media (max-width: 991px) {
    .header.is-sticky {
        padding-top:.75rem;
        padding-bottom: .75rem
    }
  }
  
  @media (min-width: 991px) {
    .header.is-sticky {
        padding-top:1.25rem;
        padding-bottom: 1.25rem
    }
  }
  
  .header-main {
    display: -moz-flex;
    display: flex;
    position: relative;
    -moz-align-items: flex-start;
    align-items: flex-start;
    -moz-justify-content: center;
    justify-content: center;
    -moz-justify-content: space-between;
    justify-content: space-between;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical
  }
  
  @media (min-width: 768px) {
    .header-main-sm {
        height:40px;
        -moz-align-items: flex-start;
        align-items: flex-start;
        -moz-flex-direction: column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .header-main-sm-md {
        height:40px;
        -moz-align-items: center;
        align-items: center;
        -moz-flex-direction: row;
        flex-direction: row;
        -ms-box-orient: horizontal;
        box-orient: horizontal
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .header-main-sm-md-lg {
        height:40px;
        -moz-align-items: center;
        align-items: center;
        -moz-flex-direction: row;
        flex-direction: row;
        -ms-box-orient: horizontal;
        box-orient: horizontal
    }
  }
  
  .header-main-logo {
    width: 150px;
    display: block;
    text-align: center;
    margin-top: .375rem
  }
  
  .header-main-logo .image-fluid {
    width: 100%;
    max-width: 100%
  }
  .header-main-logo img{
    height: 60px;
  }
  
  @media (min-width: 768px) {
    .header-main-logo-sm {
        width:170px;
        display: block;
        margin-top: 0
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .header-main-logo-sm {
        width:190px
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .header-main-logo-sm {
        width:212px
    }
  }
  
  .header-main .wallet-box {
    height: 36px;
    font-size: 13px;
    padding: .125rem .25rem;
    font-weight: 500;
    border-radius: 80px;
    background-color: rgba(217,233,255,.15)
  }
  
  .header-main .wallet-box-lt {
    color: #E8E8E8;
    font-weight: 700;
    padding: 0 .75rem 0 .5625rem
  }
  
  .header-main .wallet-box-rt {
    padding: .3125rem .625rem;
    border-radius: 80px;
    background-color: rgba(0,24,53,.75)
  }
  
  @media (max-width: 1024px) {
    .header-main-sm{
        height: auto;
    }
    .header {
        top:0;
        z-index: 120;
        position: -webkit-sticky!important;
        position: sticky!important;
        background-color: #18181B!important;
        border-bottom: 1px solid rgba(186,203,228,.125);
        -ms-box-shadow: 0 10px 20px 0 rgba(0,0,0,.35);
        box-shadow: 0 10px 20px 0 rgba(0,0,0,.35)
    }
  }
  
  .header .navbar {
    display: none;
    position: relative
  }
  
  @media (max-width: 1024px) {
    .header .navbar {
        width:100%;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
        display: block!important
    }
  
    .header .navbar .container-fluid {
        width: 100%;
        padding-left: 0!important;
        padding-right: 0!important
    }
  
    .header .navbar .navbar-nav {
        border-radius: 8px;
        background: rgb(33, 34, 38);
        padding: .625rem 0;
        margin-top: .625rem;
        border: 1px solid #000;
        -ms-box-shadow: 0 10px 20px 0 rgba(0,0,0,.75);
        box-shadow: 0 10px 20px 0 rgba(0,0,0,.75)
    }
  
    .header .navbar .navbar-nav .nav-item {
        margin-bottom: 0
    }
  
    .header .navbar .navbar-nav .nav-link {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: .75rem 1rem
    }
  
    .header .navbar .navbar-nav .nav-link:active,.header .navbar .navbar-nav .nav-link:focus,.header .navbar .navbar-nav .nav-link:hover {
        color: #E8E8E8
    }
  
    .header .navbar .navbar-nav .webapp {
        display: -moz-flex;
        display: flex;
        border-color: #18181B;
        background-color: #18181B;
        padding-top: .75rem;
        padding-bottom: .75rem
    }
  
    .header .navbar .navbar-nav .wallet-box,.header .navbar .navbar-nav .webapp {
        margin: 10px;
        width: calc(100% - 20px);
        border-radius: 6px
    }
  
    .header .navbar .navbar-nav .wallet-box {
        height: auto;
        padding: .5rem .625rem;
        justify-content: space-between
    }
  
    .header .navbar .navbar-nav .wallet-box-lt {
        padding-left: 2px
    }
  }
  
  .header .navbar-toggler {
    right: 0;
    top: -45px;
    color: #fff;
    position: absolute;
    width: 32px;
    height: 30px;
    padding: .3125rem;
    box-shadow: none!important;
    background-color: #E8E8E8!important;
    flex-wrap: wrap
  }
  
  .header .navbar-toggler-ico {
    background-color: #fff!important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 15)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
  }
  
  .header .navbar-toggler span {
    display: inline-block;
    width: 22px;
    height: 2px;
    background-color: #18181B;
    transition: all .3s ease;
    -webkit-transform-origin: 1px;
    transform-origin: 1px
  }
  
  .header .navbar-toggler span.line1,.header .navbar-toggler span.line3 {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  
  .header .navbar-toggler span.line2 {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
  
  .header .navbar-toggler.is-collapsed .line1 {
    -webkit-transform: rotate(45deg) translate(2px,-2px);
    transform: rotate(45deg) translate(2px,-2px)
  }
  
  .header .navbar-toggler.is-collapsed .line2 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateX(30px);
    transform: translateX(30px)
  }
  
  .header .navbar-toggler.is-collapsed .line3 {
    -webkit-transform: rotate(-45deg) translate(2px,2px);
    transform: rotate(-45deg) translate(2px,2px)
  }
  
  .header .navbar .collapse {
    z-index: 120;
    position: relative
  }
  
  .size-17,.size-24,.size-28 {
    display: -moz-flex;
    display: flex;
    margin-right: .3125rem;
    -moz-align-items: center;
    align-items: center
  }
  
  .size-17 svg,.size-24 svg,.size-28 svg {
    top: 0;
    fill: currentColor;
    position: relative;
    width: 100%;
    height: 100%
  }
  
  .size-28 {
    width: 28px;
    height: 28px
  }
  
  .size-17 {
    width: 18px;
    height: 18px
  }
  
  .disconnect-button {
    flex-shrink: 0;
    padding: 0!important;
    margin: 0 0 0 6px!important;
    width: 28px!important;
    height: 28px!important;
    background-color: #f23a58;
    border-radius: 50%;
    transition: all .4s ease
  }
  
  .disconnect-button:hover {
    opacity: .75;
    filter: alpha(opacity=75)
  }
  
  @media (max-width: 767px) {
    .disconnect-button {
        width:24px!important;
        height: 24px!important
    }
  }
  
  .mobile-connect {
    top: 15px;
    right: 45px;
    position: absolute;
    display: none!important
  }
  
  .mobile-connect .wallet-connect {
    color: #18181B;
    display: -moz-flex;
    display: flex;
    font-size: 14px;
    padding: .25rem .625rem;
    outline: none!important;
    border: 0!important;
    box-shadow: none!important;
    font-weight: 500;
    border-radius: 40px;
    -moz-align-items: center;
    align-items: center;
    background-color: #E8E8E8
  }
  
  .mobile-connect .wallet-connect:active,.mobile-connect .wallet-connect:focus,.mobile-connect .wallet-connect:hover {
    color: #218cc8;
    outline: none!important;
    border: 0!important;
    box-shadow: none!important;
    background-color: #E8E8E8
  }
  
  .mobile-connect .wallet-connect .size-20 {
    width: 20px;
    height: 20px;
    margin-top: -1px!important;
    margin-right: 5px
  }
  
  .mobile-connect .wallet-connect .size-20 svg {
    width: 100%;
    height: 100%
  }
  
  .navigation {
    height: 45px;
    display: none;
    position: relative;
    margin-bottom: 0
  }
  
  .navigation,.navigation-list {
    text-align: center;
    -moz-align-items: center;
    align-items: center
  }
  
  .navigation-list {
    display: -moz-flex;
    display: flex;
    margin: 0;
    padding: 0
  }
  
  .navigation-list li {
    position: relative;
    text-align: center;
    display: inline-block;
    margin: 0 0 0 .75rem;
    -moz-align-items: center;
    align-items: center
  }
  
  .navigation-list li a,.navigation-list li button {
    height: 36px;
    color: #E8E8E8;
    display: -moz-flex;
    display: flex;
    font-size: .875rem;
    line-height: 1.05rem;
    line-height: 1.5;
    padding: .375rem .5rem;
    font-weight: 700;
    -moz-align-items: center;
    align-items: center
  }
  
  .navigation-list li a.active,.navigation-list li a:hover,.navigation-list li button.active,.navigation-list li button:hover {
    color: rgb(234, 137, 22)
  }
  
  .navigation-list li a.has-child:after,.navigation-list li button.has-child:after {
    top: 0;
    color: #302d75;
    line-height: 1;
    font-size: 14px;
    content: "\f107";
    text-shadow: none;
    position: relative;
    text-transform: none;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    display: inline-block;
    margin-left: .375rem;
    -webkit-font-smoothing: antialiased
  }
  
  .navigation-list li a.has-child.active:after,.navigation-list li button.has-child.active:after {
    color: rgb(234, 137, 22)
  }
  
  .navigation-list li a.contrast,.navigation-list li a.webapp,.navigation-list li button.contrast,.navigation-list li button.webapp {
    text-transform: none
  }
  
  .navigation-list li a.webapp,.navigation-list li button.webapp {
    outline: none!important;
    font-weight: 500;
    border-radius: 40px;
    padding-left: 16px!important;
    padding-right: 16px!important;
    box-shadow: none!important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: linear-gradient(90deg, rgb(234, 137, 22) 0%, rgb(234, 124, 22) 100%);
    border: 1px solid rgba(240, 156, 56, 0.7);
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px, rgb(234 137 22 / 40%) -6px -4px 16px, rgb(255 255 255 / 20%) 9px 9px 16px inset;
    color: white;
  }
  
  .navigation-list li a.webapp:hover,.navigation-list li button.webapp:hover {
    background: linear-gradient(90deg, rgb(255, 166, 60) 0%, rgb(255, 146, 45) 100%);
    border: 1px solid rgba(240, 156, 56, 0.7);
    box-shadow: rgb(0 0 0 / 40%) 0px 4px 4px, rgb(234 137 22 / 60%) -6px -4px 16px, rgb(255 255 255 / 20%) 9px 9px 16px inset;
  }
  
  .navigation-list li a.contrast,.navigation-list li button.contrast {
    padding: 0
  }
  
  .navigation-list li a.contrast .size-24,.navigation-list li button.contrast .size-24 {
    width: 36px;
    height: 36px
  }
  
  .navigation-list li.active a,.navigation-list li:hover a,.navigation-list li:hover a.has-child:after {
    color: rgb(234, 137, 22)
  }
  
  .navigation-list li .sub-menu {
    left: 0;
    top: 100px;
    z-index: 19;
    margin: 0;
    width: 190px;
    padding: 0;
    text-align: left;
    visibility: hidden;
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    border: 1px solid #c7cfd8;
    border-radius: 0 0 8px 8px;
    background: #fff none repeat scroll 0 0;
    transition: all .3s linear .15s;
    -ms-box-shadow: 0 6px 10px 0 rgba(0,0,0,.25)!important;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.25)!important
  }
  
  .navigation-list li .sub-menu.right {
    left: auto;
    right: -14px
  }
  
  .navigation-list li .sub-menu>li {
    margin: 0;
    height: auto;
    display: block;
    padding: 0;
    text-align: left;
    background-color: transparent;
    border-bottom: 1px solid #c7cfd8
  }
  
  .navigation-list li .sub-menu>li:last-child {
    border-bottom: 0;
    border-radius: 0 0 8px 8px
  }
  
  .navigation-list li .sub-menu>li a {
    color: #302d75;
    display: block;
    text-align: left;
    position: relative;
    padding: .9375rem;
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-family: "Montserrat",sans-serif;
    transition: all .3s ease;
    background: #fff none repeat scroll 0 0
  }
  
  .navigation-list li .sub-menu>li a.active,.navigation-list li .sub-menu>li a:hover {
    color: #fff;
    border: 0!important;
    background: #302d75 none repeat scroll 0 0
  }
  
  .navigation-list li .sub-menu>li:hover .sub-menu {
    top: 0
  }
  
  .navigation-list li .sub-menu>li:last-child a {
    border-radius: 0 0 8px 8px
  }
  
  .navigation-list li .sub-menu .sub-menu {
    top: 100px;
    left: 100%
  }
  
  .navigation-list li:hover>.sub-menu {
    top: 65px;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible
  }
  
  @media (min-width: 768px) {
    .navigation-sm {
        height:45px;
        display: -moz-flex;
        display: flex
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .navigation-sm .navigation-list li a,.navigation-sm .navigation-list li button {
        font-size:.9375rem;
        line-height: 1.125rem;
        line-height: 1.5;
        padding: .375rem .9375rem
    }
  }
  
  @media (min-width: 992px) {
    .navigation-md .navigation--list {
        width:100%;
        display: -moz-flex;
        display: flex;
        text-align: center;
        display: inline-block
    }
  }
  
  @media (min-width: 992px) {
    .navigation-md .navigation--list li {
        margin:0 .125rem
    }
  
    .navigation-md .navigation--list li a {
        font-size: 1.0625rem;
        line-height: 1.275rem;
        line-height: 1.5;
        padding: .625rem .875rem
    }
  }
  
  @media (min-width: 992px) and (min-width:1200px) {
    .navigation-md .navigation--list li {
        margin:0 .125rem
    }
  
    .navigation-md .navigation--list li a {
        font-size: 1.0625rem;
        line-height: 1.275rem;
        line-height: 1.5;
        padding: .625rem .9375rem
    }
  }
  
  .page-menu {
    height: 0;
    overflow: hidden
  }
  
  .page-menu,.page-menu-wrap {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
  }
  
  .page-menu-wrap {
    opacity: 0;
    filter: alpha(opacity=0);
    pointer-events: none;
    padding: .75rem 0;
    transition: all .4s ease;
    -webkit-transform: translate3d(0,-44px,0);
    transform: translate3d(0,-44px,0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
  }
  
  .page-menu .hide-sm {
    display: none
  }
  
  .page-menu.sticky-page-menu {
    overflow: visible
  }
  
  .page-menu.sticky-page-menu .page-menu-wrap {
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    position: fixed;
    max-height: 70px;
    opacity: 1;
    filter: alpha(opacity=100);
    pointer-events: auto;
    padding: .625rem 0;
    background-color: #fff;
    border-bottom: 1px solid #c7cfd8;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -ms-box-shadow: 0 6px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 6px 10px 0 rgba(0,0,0,.15);
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: space-between;
    justify-content: space-between
  }
  
  @media (min-width: 768px) {
    .page-menu.sticky-page-menu .page-menu-wrap-sm {
        padding:.625rem 0
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .page-menu.sticky-page-menu .page-menu-wrap-sm {
        display:-moz-flex;
        display: flex;
        max-height: 100px;
        padding: .625rem 0;
        -moz-align-items: center;
        align-items: center;
        -moz-justify-content: space-between;
        justify-content: space-between
    }
  }
  
  .page-menu.sticky-page-menu .page-menu--logo {
    width: 180px;
    display: block;
    margin-top: .625rem
  }
  
  @media (min-width: 768px) {
    .page-menu.sticky-page-menu .page-menu--logo-sm {
        width:195px;
        display: block;
        margin-top: .625rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .page-menu.sticky-page-menu .page-menu--logo-sm {
        width:210px;
        margin-top: .0625rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .page-menu.sticky-page-menu .page-menu--logo-sm {
        width:225px;
        margin-top: .0625rem
    }
  }
  
  .page-menu.sticky-page-menu .page-menu--logo .image-fluid {
    width: 100%;
    max-width: 100%
  }
  
  .page-menu.sticky-page-menu .navigation--list>li a,.page-menu.sticky-page-menu .navigation--list>li a.has-child:after {
    color: #302d75
  }
  
  .page-menu.sticky-page-menu .navigation--list>li a.active,.page-menu.sticky-page-menu .navigation--list>li a.active.has-child:after,.page-menu.sticky-page-menu .navigation--list>li a.has-child:hover:after,.page-menu.sticky-page-menu .navigation--list>li a:hover {
    color: #f23a58
  }
  
  .page-menu.sticky-page-menu .navigation--list>li .sub-menu {
    top: 100px
  }
  
  .page-menu.sticky-page-menu .navigation--list>li .sub-menu>li a {
    color: #302d75
  }
  
  .page-menu.sticky-page-menu .navigation--list>li .sub-menu>li a:hover {
    color: #fff
  }
  
  .page-menu.sticky-page-menu .navigation--list>li .sub-menu>li a.active {
    color: #fff!important
  }
  
  .page-menu.sticky-page-menu .navigation--list>li:hover>a,.page-menu.sticky-page-menu .navigation--list>li:hover>a.has-child:after {
    color: #f23a58
  }
  
  .page-menu.sticky-page-menu .navigation--list>li:hover>.sub-menu {
    top: 66px
  }
  
  .sticky-hamMenu,.sub-menu-header,.zeynep {
    display: none
  }
  
  @media (max-width: 1024px) {
    .page-menu {
        display:none!important
    }
  
    .sticky-hamMenu,.sub-menu-header,.zeynep {
        display: block
    }
  
    .sticky-hamMenu {
        top: 8px;
        right: 0;
        padding: 0;
        outline: none;
        cursor: pointer;
        position: absolute;
        width: 1.875rem;
        height: 1.875rem
    }
  
    .sticky-hamMenu span,.sticky-hamMenu span:after,.sticky-hamMenu span:before {
        left: 0;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        transition: all .1s ease-out;
        background: none repeat scroll 0 0 #000
    }
  
    .sticky-hamMenu span:after,.sticky-hamMenu span:before {
        content: " ";
        display: block
    }
  
    .sticky-hamMenu span:before {
        -webkit-transform: translateY(-9px);
        transform: translateY(-9px)
    }
  
    .sticky-hamMenu span:after {
        -webkit-transform: translateY(9px);
        transform: translateY(9px)
    }
  
    .sticky-hamMenu:hover span,.sticky-hamMenu:hover span:after,.sticky-hamMenu:hover span:before {
        background: none repeat scroll 0 0 #302d75
    }
  
    .sticky-hamMenu.active span {
        background: transparent
    }
  
    .sticky-hamMenu.active span:before {
        background: none repeat scroll 0 0 #302d75;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }
  
    .sticky-hamMenu.active span:after {
        background: none repeat scroll 0 0 #302d75;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }
  
    .page-menu .sticky-hamMenu {
        top: 1rem
    }
  
    .page-menu .sticky-hamMenu span,.page-menu .sticky-hamMenu span:after,.page-menu .sticky-hamMenu span:before {
        background: none repeat scroll 0 0 #000
    }
  
    .page-menu .sticky-hamMenu:hover span,.page-menu .sticky-hamMenu:hover span:after,.page-menu .sticky-hamMenu:hover span:before {
        background: none repeat scroll 0 0 #302d75
    }
  
    .page-menu .sticky-hamMenu.active span {
        background: transparent
    }
  
    .page-menu .sticky-hamMenu.active span:before {
        background: none repeat scroll 0 0 #302d75;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg)
    }
  
    .page-menu .sticky-hamMenu.active span:after {
        background: none repeat scroll 0 0 #302d75;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }
  
    .transition-med {
        transition: all .35s
    }
  }
  
  @media (min-width: 768px) {
    .page-menu .sticky-hamMenu.desktop {
        display:block
    }
  }
  
  body.noScroll {
    overflow: hidden!important
  }
  
  @media (max-width: 567px) {
    .hidden-xs-device {
        display:none
    }
  }
  
  @media (max-width: 1024px) {
    .mobile-connect {
        display:block!important
    }
  }
  
  @media (max-width: 1024px) {
    .navigation {
        display:none!important
    }
  
    .header .navbar {
        display: block!important
    }
  }
  
  @media screen and (min-width: 768px) and (max-width:1024px) {
    .header-main-logo {
        margin-top:0px;
        text-align: left;
    }
  
    .header .navbar-toggler {
        top: -45px
    }
  
    .header .navbar .navbar-nav {
        margin-top: 0!important
    }
  }
  
  @media (min-width: 1025px) {
    .header-main {
        -moz-align-items:center;
        align-items: center;
        -moz-flex-direction: row;
        flex-direction: row;
        -ms-box-orient: horizontal;
        box-orient: horizontal
    }
  }
  
  button[data-balloon] {
    overflow: visible
  }
  
  [data-balloon] {
    cursor: pointer;
    border: 0;
    position: relative;
    background: transparent;
    z-index: 9!important
  }
  
  [data-balloon]:after,[data-balloon]:before {
    left: 50%;
    bottom: 100%;
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    pointer-events: none;
    z-index: 99999!important;
    transition: all .18s ease-out .18s;
    -webkit-transform: translate(-50%,10px);
    transform: translate(-50%,10px);
    -webkit-transform-origin: top;
    transform-origin: top
  }
  
  [data-balloon]:after {
    color: rgb(234, 137, 22);
    border-radius: 4px;
    content: attr(data-balloon);
    font-size: 13px;
    line-height: 1.4;
    padding: .75em 1em;
    white-space: nowrap;
    margin-bottom: 11px;
    background: rgba(33, 34, 38, .95)
  }
  
  [data-balloon]:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(217, 233, 255, 0.9)' d='M2.658 0h32.004c-6 0-11.627 12.002-16.002 12.002C14.285 12.002 8.594 0 2.658 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 6px;
    width: 18px;
    content: "";
    margin-bottom: 5px
  }
  
  [data-balloon]:hover:after,[data-balloon]:hover:before,[data-balloon][data-balloon-visible]:after,[data-balloon][data-balloon-visible]:before {
    opacity: 1;
    filter: alpha(opacity=100);
    pointer-events: auto;
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
  }
  
  [data-balloon].font-awesome:after {
    font-family: "FontAwesome"
  }
  
  [data-balloon][data-balloon-break]:after {
    white-space: pre-wrap
  }
  
  [data-balloon-pos=down]:after,[data-balloon-pos=down]:before {
    left: 50%;
    top: 100%;
    bottom: auto;
    -webkit-transform: translate(-50%,-10px);
    transform: translate(-50%,-10px)
  }
  
  [data-balloon-pos=down]:after {
    margin-top: 11px
  }
  
  [data-balloon-pos=down]:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='12'%3E%3Cpath fill='rgba(217, 233, 255, 0.9)' d='M33.342 12H1.338c6 0 11.627-12.002 16.002-12.002C21.715-.002 27.406 12 33.342 12z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 6px;
    width: 18px;
    margin-top: 5px;
    margin-bottom: 0
  }
  
  [data-balloon-pos=down]:hover:after,[data-balloon-pos=down]:hover:before,[data-balloon-pos=down][data-balloon-visible]:after,[data-balloon-pos=down][data-balloon-visible]:before {
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
  }
  
  [data-balloon-pos=left]:after,[data-balloon-pos=left]:before {
    top: 50%;
    left: auto;
    right: 100%;
    bottom: auto;
    -webkit-transform: translate(10px,-50%);
    transform: translate(10px,-50%)
  }
  
  [data-balloon-pos=left]:after {
    margin-right: 11px
  }
  
  [data-balloon-pos=left]:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(217, 233, 255, 0.9)' d='M0 33.342V1.338c0 6 12.002 11.627 12.002 16.002C12.002 21.715 0 27.406 0 33.342z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 18px;
    width: 6px;
    margin-right: 5px;
    margin-bottom: 0
  }
  
  [data-balloon-pos=left]:hover:after,[data-balloon-pos=left]:hover:before,[data-balloon-pos=left][data-balloon-visible]:after,[data-balloon-pos=left][data-balloon-visible]:before {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  
  [data-balloon-pos=right]:after,[data-balloon-pos=right]:before {
    bottom: auto;
    left: 100%;
    top: 50%;
    -webkit-transform: translate(-10px,-50%);
    transform: translate(-10px,-50%)
  }
  
  [data-balloon-pos=right]:after {
    margin-left: 11px
  }
  
  [data-balloon-pos=right]:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='36'%3E%3Cpath fill='rgba(217, 233, 255, 0.9)' d='M12 2.658v32.004c0-6-12.002-11.627-12.002-16.002C-.002 14.285 12 8.594 12 2.658z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 18px;
    width: 6px;
    margin-bottom: 0;
    margin-left: 5px
  }
  
  [data-balloon-pos=right]:hover:after,[data-balloon-pos=right]:hover:before,[data-balloon-pos=right][data-balloon-visible]:after,[data-balloon-pos=right][data-balloon-visible]:before {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  
  [data-balloon-length]:after {
    white-space: normal
  }
  
  [data-balloon-length=small]:after {
    width: 80px!important;
    max-width: 80px!important;
    text-align: center!important
  }
  
  [data-balloon-length=medium]:after {
    width: 150px;
    text-align: left
  }
  
  [data-balloon-length=large]:after {
    width: 280px;
    text-align: justify
  }
  
  [data-balloon-length=xlarge]:after {
    width: 90vw;
    text-align: left
  }
  
  @media screen and (min-width: 768px) {
    [data-balloon-length=xlarge]:after {
        width:350px
    }
  }
  
  [data-balloon-length=fit]:after {
    width: 100%
  }
  
  @media (min-width: 1024px) {
    .c-navigation--list li span.plus,.responsive--menu {
        display:none!important
    }
  }
  
  .responsive--menu {
    left: 0;
    top: 67px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: left;
    z-index: 99999999;
    max-height: calc(100% - 67px);
    padding: 10px 0 40px!important;
    background-color: #f8f9fa;
    -webkit-user-select: none;
    user-select: none;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: all .3s ease;
    position: fixed!important
  }
  
  .responsive--menu:after {
    content: "";
    display: block;
    position: fixed;
    width: 0;
    height: 0;
    left: 0;
    bottom: 0
  }
  
  .responsive--menu.is--active {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
  
  .responsive--menu .mainNav {
    margin: 0
  }
  
  .responsive--menu .mainNav li {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    display: -moz-flex;
    display: flex;
    position: relative;
    border-bottom: 1px solid #fff;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical
  }
  
  .responsive--menu .mainNav li:before {
    display: none
  }
  
  .responsive--menu .mainNav li:first-child {
    border-top: 0
  }
  
  .responsive--menu .mainNav li a {
    width: 100%;
    color: #302d75;
    display: block;
    font-size: 1.125rem;
    padding: .75rem 1.25rem;
    border: 0!important;
    text-align: left!important;
    border-radius: 0;
    text-decoration: none!important
  }
  
  .responsive--menu .mainNav li a:active,.responsive--menu .mainNav li a:focus,.responsive--menu .mainNav li a:hover {
    color: #302d75;
    background-color: hsla(0,0%,100%,.5)
  }
  
  .responsive--menu .mainNav li a.active {
    color: #302d75;
    font-weight: 700
  }
  
  .responsive--menu .mainNav li span.plus {
    top: 0;
    right: 0;
    z-index: 9;
    color: #000;
    cursor: pointer;
    font-size: 20px;
    display: -moz-flex;
    display: flex;
    text-align: center;
    position: absolute;
    width: 50px;
    height: 50px;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center;
    transition: all .3s ease
  }
  
  .responsive--menu .sub-menu {
    margin: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    z-index: 99999999;
    padding: 0;
    background-color: #f8f9fa;
    transition: all .8s
  }
  
  .responsive--menu .sub-menu li,.responsive--menu .sub-menu li:first-child {
    border-top: 1px solid #f8f9fa
  }
  
  .responsive--menu .sub-menu li a {
    color: #000;
    position: relative;
    font-size: 1.125rem;
    padding: .75rem 2.5rem
  }
  
  .responsive--menu .sub-menu li a:active,.responsive--menu .sub-menu li a:focus,.responsive--menu .sub-menu li a:hover {
    color: #302d75;
    background-color: hsla(0,0%,100%,.5)
  }
  
  .responsive--menu .sub-menu li a.active {
    color: #302d75;
    font-weight: 700
  }
  
  .responsive--menu .sub-menu.is--active {
    height: auto;
    max-height: 500px
  }
  
  .responsive--menu .mainNav li.is--active {
    background-color: #fff
  }
  
  .responsive--menu .mainNav li.is--active span.plus {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
  }
  
  .responsive--menu .mainNav li.is--active .sub-menu {
    background-color: #fff
  }
  
  .fs-16,.how-it-works .description p,.what-new-wrap p {
    font-size: 17px;
    font-weight: 300
  }
  
  .fs-13 {
    font-size: 13px
  }
  
  .fs-13,.fs-20 {
    line-height: 1.5
  }
  
  .fs-20 {
    font-size: 20px
  }
  
  .fs-30 {
    font-size: 24px;
    line-height: 1.5
  }
  
  @media (min-width: 768px) {
    .fs-30-sm {
        font-size:27px
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .fs-30-sm {
        font-size:30
    }
  }
  
  .text-50 {
    opacity: .5;
    filter: alpha(opacity=50)
  }
  
  .section-quick-setup {
    position: relative;
    background-color: rgb(33, 34, 38)
  }
  
  .section-quick-setup-wrap {
    padding: 3.125rem 1.5625rem 0;
    background-color: #18181B;
    border-radius: 25px;
    margin: 0 5px
  }
  
  @media (min-width: 768px) {
    .section-quick-setup-wrap-sm {
        padding:3.125rem 1.5625rem 1.25rem;
        border-radius: 80px 15px 5px 5px;
        margin: 0
    }
  
    .section-quick-setup-wrap-sm .icon-138 {
        width: 90px;
        height: 90px
    }
  
    .section-quick-setup-wrap-sm .w-20:before {
        display: none
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .section-quick-setup-wrap-sm {
        padding:1.875rem;
        border-radius: 60px
    }
  
    .section-quick-setup-wrap-sm .icon-138 {
        width: 130px;
        height: 130px
    }
  
    .section-quick-setup-wrap-sm .w-20:before {
        display: block
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .section-quick-setup-wrap-sm {
        padding:2.5rem;
        border-radius: 80px
    }
  }
  
  .section-quick-setup-wrap .size-18 {
    top: 0;
    right: 50px
  }
  
  @media (max-width: 767px) {
    .section-quick-setup-wrap {
        flex-wrap:wrap!important
    }
  }
  
  .section-quick-setup .w-20 {
    width: 100%;
    max-width: 20%;
    position: relative;
    -moz-flex: 0 0 20%;
    flex: 0 0 20%
  }
  
  @media (max-width: 767px) {
    .section-quick-setup .w-20 {
        width:100%!important;
        max-width: 100%!important;
        -moz-flex: 0 0 100%!important;
        flex: 0 0 100%!important;
        margin-bottom: 3.75rem
    }
  }
  
  .section-quick-setup .w-20:before {
    top: 45px;
    content: "";
    right: -25px;
    position: absolute;
    width: 50px;
    height: 50px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M18.6 10.5l-4-3.7c-.6-.6-1.5-.8-2.3-.5-.8.2-1.4.8-1.6 1.6-.2.7.1 1.5.7 2L7 9.8c-1.3 0-2.3.9-2.3 2.1 0 1.2 1 2.1 2.3 2.1h4.5c-2.4 1.9.7 5.1 3.1 3.1l4.1-3.7c.4-.4.7-.9.7-1.5-.1-.5-.4-1-.8-1.4zm-.7 2.3l-4.1 3.7c-.5.4-1.3.4-1.7 0-.5-.4-.5-1.2 0-1.6l1.1-1c.1-.1.2-.3.1-.5s-.2-.3-.4-.3H7.1c-.7 0-1.2-.5-1.2-1.1 0-.3.1-.6.4-.8.2-.2.5-.3.9-.3H13c.2 0 .4-.2.4-.3.1-.2 0-.4-.1-.5l-1.1-1c-.5-.4-.5-1.2 0-1.6.5-.4 1.3-.4 1.7 0l4 3.7c.4.4.4 1.2 0 1.6z' fill='%23d9e9ff'/%3E%3C/svg%3E");
    background-size: 50px auto
  }
  
  @media (max-width: 767px) {
    .section-quick-setup .w-20:before {
        display:none
    }
  }
  
  .section-quick-setup .w-20:last-child:before {
    display: none
  }
  
  .section-quick-setup .title {
    color: #bacbe4;
    min-height: 50px;
    font-size: 1.25rem;
    font-weight: 400!important
  }
  
  .section-quick-setup .title span {
    width: 100%;
    display: inline-block
  }
  
  .section-quick-setup .title strong {
    font-weight: 700!important
  }
  
  @media (max-width: 992px) {
    .section-quick-setup .title {
        font-size:15px
    }
  }
  
  @media (max-width: 767px) {
    .section-quick-setup {
        margin-top:-50px;
        padding-top: 2.5rem!important;
        border-radius: 40px 40px 0 0
    }
  }
  
  .link-primary {
    color: #218cc8!important;
    border-bottom: 1px solid transparent;
    transition: all .3s ease
  }
  
  .link-primary:hover {
    border-bottom: 1px solid #218cc8
  }
  
  .tooltip {
    position: relative;
    min-width: 320px!important;
    max-width: 320px!important
  }
  
  @media (max-width: 767px) {
    .tooltip {
        min-width:280px;
        max-width: 280px!important
    }
  }
  
  .tooltip-inner {
    font-size: 13px;
    line-height: 1.4;
    min-width: 320px;
    padding: 14px 25px!important;
    color: #000!important;
    border-radius: 80px!important;
    max-width: 320px!important;
    font-weight: 300!important;
    font-family: "Montserrat",sans-serif!important;
    background-color: #E8E8E8!important
  }
  
  @media (max-width: 767px) {
    .tooltip-inner {
        font-size:12px;
        min-width: 280px;
        max-width: 280px!important
    }
  }
  
  .tooltip .tooltip-arrow {
    bottom: -6px!important;
    border: 0!important;
    width: 20px!important;
    height: 20px!important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 24'%3E%3Cpath d='M19.5 22c-1.5 2.7-5.4 2.7-7 0L8 14 0 0h32l-8 14-4.5 8z' fill='%23d9e9ff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 20px auto
  }
  
  .tooltip .tooltip-arrow:before {
    display: none!important
  }
  
  .how-it-works .icon-138,.icon-138 {
    display: block;
    width: 130px;
    height: 130px;
    border-radius: 50%
  }
  
  .size-18 {
    top: 10px;
    z-index: 1;
    right: -10px;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%
  }
  
  .how-it-works,.node-tier,.section,.swap-pages,.what-new {
    padding: 6.25rem 0 2.5rem;
    background-color: #18181B
  }
  
  @media (min-width: 768px) {
    .how-it-works-sm,.node-tier-sm,.section-sm,.swap-pages-sm,.what-new-sm {
        padding:3.125rem 0
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .how-it-works-sm,.node-tier-sm,.section-sm,.swap-pages-sm,.what-new-sm {
        padding:3.75rem 0
    }
  }
  
  .how-it-works h1,.node-tier h1,.section h1,.swap-pages h1,.what-new h1 {
    font-size: 2.5rem;
    font-weight: 900!important
  }
  
  @media (max-width: 767px) {
    .how-it-works h1,.node-tier h1,.section h1,.swap-pages h1,.what-new h1 {
        font-size:2rem
    }
  }
  
  .how-it-works h3,.node-tier h3,.section h3,.swap-pages h3,.what-new h3 {
    font-size: 1.225rem;
    font-weight: 900!important
  }
  
  @media (max-width: 767px) {
    .how-it-works h3,.node-tier h3,.section h3,.swap-pages h3,.what-new h3 {
        font-size:1.125rem
    }
  }
  
  .how-it-works .pl-4,.node-tier .pl-4,.section .pl-4,.swap-pages .pl-4,.what-new .pl-4 {
    padding-left: 1.5625rem
  }
  
  @media (max-width: 767px) {
    .how-it-works .pl-4,.node-tier .pl-4,.section .pl-4,.swap-pages .pl-4,.what-new .pl-4 {
        padding-left:.9375rem
    }
  }
  
  .how-it-works .box,.node-tier .box,.section .box,.swap-pages .box,.what-new .box {
    min-height: 76px;
    box-sizing: border-box;
    padding: .9375rem 1.25rem;
    background-color: rgb(33, 34, 38);
    border: 1px solid #DD8837;
    border-radius: 10px
  }
  
  .how-it-works .box.bg-blue,.node-tier .box.bg-blue,.section .box.bg-blue,.swap-pages .box.bg-blue,.what-new .box.bg-blue {
    color: white;
    background: linear-gradient(89.97deg, rgb(234, 137, 22) 0.03%, rgb(235, 106, 33) 94.43%)
  }
  
  .how-it-works .fs-30,.node-tier .fs-30,.section .fs-30,.swap-pages .fs-30,.what-new .fs-30 {
    font-size: 20px;
    line-height: 1.2
  }
  
  @media (min-width: 768px) {
    .how-it-works .fs-30-sm,.node-tier .fs-30-sm,.section .fs-30-sm,.swap-pages .fs-30-sm,.what-new .fs-30-sm {
        font-size:23px;
        line-height: 1.2
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .how-it-works .fs-30-sm,.node-tier .fs-30-sm,.section .fs-30-sm,.swap-pages .fs-30-sm,.what-new .fs-30-sm {
        font-size:27px;
        line-height: 1.1
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .how-it-works .fs-30-sm,.node-tier .fs-30-sm,.section .fs-30-sm,.swap-pages .fs-30-sm,.what-new .fs-30-sm {
        font-size:30px;
        line-height: 1.1
    }
  }
  
  .how-it-works .fs-50,.node-tier .fs-50,.section .fs-50,.swap-pages .fs-50,.what-new .fs-50 {
    font-size: 25px;
    line-height: 1.2
  }
  
  @media (min-width: 768px) {
    .how-it-works .fs-50-sm,.node-tier .fs-50-sm,.section .fs-50-sm,.swap-pages .fs-50-sm,.what-new .fs-50-sm {
        font-size:28px;
        line-height: 1.2
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .how-it-works .fs-50-sm,.node-tier .fs-50-sm,.section .fs-50-sm,.swap-pages .fs-50-sm,.what-new .fs-50-sm {
        font-size:34px;
        line-height: 1.1
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .how-it-works .fs-50-sm,.node-tier .fs-50-sm,.section .fs-50-sm,.swap-pages .fs-50-sm,.what-new .fs-50-sm {
        font-size:40px;
        line-height: 1.1
    }
  }
  
  .how-it-works .small-box,.node-tier .small-box,.section .small-box,.swap-pages .small-box,.what-new .small-box {
    font-size: 20px;
    padding: .625rem 1.25rem;
    background-color: #01284e;
    border-radius: 40px
  }
  
  .how-it-works .box-rounded,.node-tier .box-rounded,.section .box-rounded,.swap-pages .box-rounded,.what-new .box-rounded {
    padding-bottom: 0;
    background-color: rgb(33, 34, 38);
    border-radius: 10px
  }
  
  .how-it-works .box-rounded .table,.node-tier .box-rounded .table,.section .box-rounded .table,.swap-pages .box-rounded .table,.what-new .box-rounded .table {
    box-shadow: none!important
  }
  
  .how-it-works .box-rounded .table .button-set,.node-tier .box-rounded .table .button-set,.section .box-rounded .table .button-set,.swap-pages .box-rounded .table .button-set,.what-new .box-rounded .table .button-set {
    left: -2px;
    width: auto!important
  }
  
  .how-it-works .box-rounded .table .button-set .eds-checked,.node-tier .box-rounded .table .button-set .eds-checked,.section .box-rounded .table .button-set .eds-checked,.swap-pages .box-rounded .table .button-set .eds-checked,.what-new .box-rounded .table .button-set .eds-checked {
    top: -12px;
    -webkit-transform: scale(.85);
    transform: scale(.85)
  }
  
  .how-it-works .box-rounded .table .button-set.is-loading:after,.node-tier .box-rounded .table .button-set.is-loading:after,.section .box-rounded .table .button-set.is-loading:after,.swap-pages .box-rounded .table .button-set.is-loading:after,.what-new .box-rounded .table .button-set.is-loading:after {
    border-top: 2px solid #E8E8E8
  }
  
  .how-it-works .box-rounded .table thead .button-set.is-loading:after,.node-tier .box-rounded .table thead .button-set.is-loading:after,.section .box-rounded .table thead .button-set.is-loading:after,.swap-pages .box-rounded .table thead .button-set.is-loading:after,.what-new .box-rounded .table thead .button-set.is-loading:after {
    border-top: 2px solid #0ecff8
  }
  
  .how-it-works .box-rounded .table tbody,.how-it-works .box-rounded .table thead,.node-tier .box-rounded .table tbody,.node-tier .box-rounded .table thead,.section .box-rounded .table tbody,.section .box-rounded .table thead,.swap-pages .box-rounded .table tbody,.swap-pages .box-rounded .table thead,.what-new .box-rounded .table tbody,.what-new .box-rounded .table thead {
    border: 0
  }
  
  .how-it-works .box-rounded .table tbody td,.how-it-works .box-rounded .table tbody th,.how-it-works .box-rounded .table thead td,.how-it-works .box-rounded .table thead th,.node-tier .box-rounded .table tbody td,.node-tier .box-rounded .table tbody th,.node-tier .box-rounded .table thead td,.node-tier .box-rounded .table thead th,.section .box-rounded .table tbody td,.section .box-rounded .table tbody th,.section .box-rounded .table thead td,.section .box-rounded .table thead th,.swap-pages .box-rounded .table tbody td,.swap-pages .box-rounded .table tbody th,.swap-pages .box-rounded .table thead td,.swap-pages .box-rounded .table thead th,.what-new .box-rounded .table tbody td,.what-new .box-rounded .table tbody th,.what-new .box-rounded .table thead td,.what-new .box-rounded .table thead th {
    padding: 12px;
    border: 0;
    font-size: 14px;
    font-weight: 300;
    box-shadow: none!important;
    vertical-align: middle!important
  }
  
  .how-it-works .box-rounded .table tbody td p.detail-info,.how-it-works .box-rounded .table tbody th p.detail-info,.how-it-works .box-rounded .table thead td p.detail-info,.how-it-works .box-rounded .table thead th p.detail-info,.node-tier .box-rounded .table tbody td p.detail-info,.node-tier .box-rounded .table tbody th p.detail-info,.node-tier .box-rounded .table thead td p.detail-info,.node-tier .box-rounded .table thead th p.detail-info,.section .box-rounded .table tbody td p.detail-info,.section .box-rounded .table tbody th p.detail-info,.section .box-rounded .table thead td p.detail-info,.section .box-rounded .table thead th p.detail-info,.swap-pages .box-rounded .table tbody td p.detail-info,.swap-pages .box-rounded .table tbody th p.detail-info,.swap-pages .box-rounded .table thead td p.detail-info,.swap-pages .box-rounded .table thead th p.detail-info,.what-new .box-rounded .table tbody td p.detail-info,.what-new .box-rounded .table tbody th p.detail-info,.what-new .box-rounded .table thead td p.detail-info,.what-new .box-rounded .table thead th p.detail-info {
    color: #fff;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0
  }
  
  @media (max-width: 767px) {
    .how-it-works .box-rounded .table tbody td,.how-it-works .box-rounded .table tbody th,.how-it-works .box-rounded .table thead td,.how-it-works .box-rounded .table thead th,.node-tier .box-rounded .table tbody td,.node-tier .box-rounded .table tbody th,.node-tier .box-rounded .table thead td,.node-tier .box-rounded .table thead th,.section .box-rounded .table tbody td,.section .box-rounded .table tbody th,.section .box-rounded .table thead td,.section .box-rounded .table thead th,.swap-pages .box-rounded .table tbody td,.swap-pages .box-rounded .table tbody th,.swap-pages .box-rounded .table thead td,.swap-pages .box-rounded .table thead th,.what-new .box-rounded .table tbody td,.what-new .box-rounded .table tbody th,.what-new .box-rounded .table thead td,.what-new .box-rounded .table thead th {
        font-size:13px;
        min-width: 120px
    }
  
    .how-it-works .box-rounded .table tbody td:first-child,.how-it-works .box-rounded .table tbody th:first-child,.how-it-works .box-rounded .table thead td:first-child,.how-it-works .box-rounded .table thead th:first-child,.node-tier .box-rounded .table tbody td:first-child,.node-tier .box-rounded .table tbody th:first-child,.node-tier .box-rounded .table thead td:first-child,.node-tier .box-rounded .table thead th:first-child,.section .box-rounded .table tbody td:first-child,.section .box-rounded .table tbody th:first-child,.section .box-rounded .table thead td:first-child,.section .box-rounded .table thead th:first-child,.swap-pages .box-rounded .table tbody td:first-child,.swap-pages .box-rounded .table tbody th:first-child,.swap-pages .box-rounded .table thead td:first-child,.swap-pages .box-rounded .table thead th:first-child,.what-new .box-rounded .table tbody td:first-child,.what-new .box-rounded .table tbody th:first-child,.what-new .box-rounded .table thead td:first-child,.what-new .box-rounded .table thead th:first-child {
        min-width: 70px
    }
  }
  .sort{
    color: rgb(234, 137, 22) !important;
  }
  .how-it-works .box-rounded .table thead th,.node-tier .box-rounded .table thead th,.section .box-rounded .table thead th,.swap-pages .box-rounded .table thead th,.what-new .box-rounded .table thead th {
    cursor: pointer;
    color: rgb(234, 137, 22);
    background-color: rgba(0,0,0,.75);
    transition: all .3s ease
  }
  
  .how-it-works .box-rounded .table thead th:first-child,.node-tier .box-rounded .table thead th:first-child,.section .box-rounded .table thead th:first-child,.swap-pages .box-rounded .table thead th:first-child,.what-new .box-rounded .table thead th:first-child {
    padding-left: 20px;
    border-top-left-radius: 10px
  }
  
  .how-it-works .box-rounded .table thead th:last-child,.node-tier .box-rounded .table thead th:last-child,.section .box-rounded .table thead th:last-child,.swap-pages .box-rounded .table thead th:last-child,.what-new .box-rounded .table thead th:last-child {
    padding-right: 20px;
    border-top-right-radius: 10px
  }
  
  .how-it-works .box-rounded .table thead th .sort,.node-tier .box-rounded .table thead th .sort,.section .box-rounded .table thead th .sort,.swap-pages .box-rounded .table thead th .sort,.what-new .box-rounded .table thead th .sort {
    top: 1px;
    margin-left: 2px;
    padding: 2px;
    color: inherit;
    font-size: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    transition: all .3s ease
  }
  
  .how-it-works .box-rounded .table thead th .sort.is-active,.node-tier .box-rounded .table thead th .sort.is-active,.section .box-rounded .table thead th .sort.is-active,.swap-pages .box-rounded .table thead th .sort.is-active,.what-new .box-rounded .table thead th .sort.is-active {
    opacity: .7;
    filter: alpha(opacity=70);
    visibility: visible;
    color: #53bde5
  }
  
  .how-it-works .box-rounded .table thead th .sort.sort-desc,.node-tier .box-rounded .table thead th .sort.sort-desc,.section .box-rounded .table thead th .sort.sort-desc,.swap-pages .box-rounded .table thead th .sort.sort-desc,.what-new .box-rounded .table thead th .sort.sort-desc {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  
  .how-it-works .box-rounded .table thead th:hover,.node-tier .box-rounded .table thead th:hover,.section .box-rounded .table thead th:hover,.swap-pages .box-rounded .table thead th:hover,.what-new .box-rounded .table thead th:hover {
    color: rgb(234, 137, 22)
  }
  
  .how-it-works .box-rounded .table thead th:hover .sort,.node-tier .box-rounded .table thead th:hover .sort,.section .box-rounded .table thead th:hover .sort,.swap-pages .box-rounded .table thead th:hover .sort,.what-new .box-rounded .table thead th:hover .sort {
    visibility: visible;
    opacity: .7;
    filter: alpha(opacity=70)
  }
  
  .how-it-works .box-rounded .table thead th:hover .sort.is-active,.node-tier .box-rounded .table thead th:hover .sort.is-active,.section .box-rounded .table thead th:hover .sort.is-active,.swap-pages .box-rounded .table thead th:hover .sort.is-active,.what-new .box-rounded .table thead th:hover .sort.is-active {
    opacity: .7;
    filter: alpha(opacity=70);
    visibility: visible;
    color: #53bde5
  }
  
  .how-it-works .box-rounded .table tbody td,.node-tier .box-rounded .table tbody td,.section .box-rounded .table tbody td,.swap-pages .box-rounded .table tbody td,.what-new .box-rounded .table tbody td {
    color: #E8E8E8
  }
  
  .how-it-works .box-rounded .table tbody td:first-child,.node-tier .box-rounded .table tbody td:first-child,.section .box-rounded .table tbody td:first-child,.swap-pages .box-rounded .table tbody td:first-child,.what-new .box-rounded .table tbody td:first-child {
    padding-left: 20px
  }
  
  .how-it-works .box-rounded .table tbody td:last-child,.node-tier .box-rounded .table tbody td:last-child,.section .box-rounded .table tbody td:last-child,.swap-pages .box-rounded .table tbody td:last-child,.what-new .box-rounded .table tbody td:last-child {
    padding-right: 20px
  }
  
  .how-it-works .box-rounded .table tbody tr:last-child td:first-child,.node-tier .box-rounded .table tbody tr:last-child td:first-child,.section .box-rounded .table tbody tr:last-child td:first-child,.swap-pages .box-rounded .table tbody tr:last-child td:first-child,.what-new .box-rounded .table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px
  }
  
  .how-it-works .box-rounded .table tbody tr:last-child td:last-child,.node-tier .box-rounded .table tbody tr:last-child td:last-child,.section .box-rounded .table tbody tr:last-child td:last-child,.swap-pages .box-rounded .table tbody tr:last-child td:last-child,.what-new .box-rounded .table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px
  }
  
  .how-it-works .box-rounded .table tbody tr:nth-child(odd),.node-tier .box-rounded .table tbody tr:nth-child(odd),.section .box-rounded .table tbody tr:nth-child(odd),.swap-pages .box-rounded .table tbody tr:nth-child(odd),.what-new .box-rounded .table tbody tr:nth-child(odd) {
    background-color: rgba(217,233,255,.01)
  }
  
  .how-it-works .box-rounded .table tbody tr:nth-child(2n),.node-tier .box-rounded .table tbody tr:nth-child(2n),.section .box-rounded .table tbody tr:nth-child(2n),.swap-pages .box-rounded .table tbody tr:nth-child(2n),.what-new .box-rounded .table tbody tr:nth-child(2n) {
    background-color: rgba(0,0,0,.5)
  }
  
  .how-it-works .box-rounded .table .node-detail,.node-tier .box-rounded .table .node-detail,.section .box-rounded .table .node-detail,.swap-pages .box-rounded .table .node-detail,.what-new .box-rounded .table .node-detail {
    color: red
  }
  
  .how-it-works .box-count h6,.node-tier .box-count h6,.section .box-count h6,.swap-pages .box-count h6,.what-new .box-count h6 {
    font-weight: 400!important
  }
  
  .claim-button-info {
    position: relative
  }
  
  .claim-button-info .info-block {
    top: -14px;
    right: -12px;
    position: absolute
  }
  
  .swap-pages {
    padding: 3.125rem 0
  }
  
  @media (min-width: 768px) {
    .swap-pages-sm {
        padding:3.125rem 0
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .swap-pages-sm {
        padding:3.75rem 0
    }
  }
  
  .select-dropdown {
    width: 100%;
    max-width: 150px;
    position: relative
  }
  
  .select-dropdown select {
    font-size: 15px;
    outline: none!important;
    box-shadow: none!important
  }
  
  .select-dropdown select,.select-dropdown select:focus {
    color: #E8E8E8;
    border-color: #27acde;
    background-color: #27acde
  }
  
  .select-dropdown select option {
    padding: .375rem 0
  }
  
  .select-dropdown .css-ha8kg {
    top: 12px;
    right: 10px;
    z-index: 2;
    color: #E8E8E8;
    position: absolute;
    width: 14px;
    height: 14px;
    pointer-events: none;
    background-color: initial
  }
  
  .what-new {
    padding: 1.25rem 0
  }
  
  @media (max-width: 480px) {
    .how-it-works {
        background-color:rgb(33, 34, 38)
    }
  }
  
  @media (max-width: 480px) {
    .how-it-works_main {
        background-color:#18181B;
        margin: 0 20px;
        -moz-flex-direction: column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical;
        border-radius: 25px
    }
  }
  
  .how-it-works .icon-138 {
    -moz-flex: 0 0 138px;
    flex: 0 0 138px
  }
  
  @media (max-width: 480px) {
    .how-it-works .icon-138 {
        position:absolute;
        width: 60%;
        height: auto;
        left: 50%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%)
    }
  }
  
  .how-it-works .description {
    padding-left: 1.875rem
  }
  
  @media (max-width: 480px) {
    .how-it-works .description {
        text-align:center;
        padding: 1.875rem
    }
  }
  
  .how-it-works .description h4 {
    text-transform: uppercase
  }
  
  .card-small {
    padding: 2.1875rem 2.1875rem 1.5625rem 1.5625rem;
    border-radius: 20px;
    background-color: rgb(33, 34, 38)
  }
  
  @media (max-width: 767px) {
    .card-small {
        padding:1.5625rem 1.875rem .9375rem .9375rem
    }
  }
  
  .card-small .form-control {
    height: 52px;
    color: #E8E8E8;
    padding: .625rem .875rem;
    outline: none!important;
    border: 0!important;
    background-color: #18181B;
    box-shadow: none!important;
    border-radius: 10px 4px 4px 4px
  }
  
  .card-small .form-control::-webkit-input-placeholder {
    color: #E8E8E8;
    opacity: .5;
    filter: alpha(opacity=50)
  }
  
  .card-small .form-control::placeholder {
    color: #E8E8E8;
    opacity: .5;
    filter: alpha(opacity=50)
  }
  
  .card-small .form-control:focus {
    color: #E8E8E8;
    background-color: #18181B
  }
  
  .card-small .button {
    height: 52px;
    border: 0;
    max-height: 52px;
    font-size: 1rem;
    padding: .75rem 1.875rem;
    outline: none!important;
    box-shadow: none!important;
    font-weight: 500;
    color: white;
    background: linear-gradient(89.97deg, rgb(234, 137, 22) 0.03%, rgb(235, 106, 33) 94.43%);
    border-radius: 40px
  }
  
  /* .card-small .button:active,.card-small .button:focus,.card-small .button:hover {
    color: #218cc8
  } */
  
  .card-small .button:disabled {
    color: #18181B;
    opacity: .5;
    filter: alpha(opacity=50);
    background-color: #E8E8E8
  }
  
  .card-small .button.button-block {
    min-height: 56px!important
  }
  
  .card-small .size-18 {
    top: -16px;
    right: -12px
  }
  
  @media (max-width: 767px) {
    .card-small .w-60,.card-small .w-70 {
        width:100%!important;
        max-width: 100%!important;
        padding-right: 0!important;
        margin-bottom: 1.5rem!important
    }
  }
  
  .card-small .w-20,.card-small .w-30 {
    width: 100%!important;
    max-width: 70%!important
  }
  
  @media (max-width: 767px) {
    .card-small .sm\:w-30 {
        max-width:100%!important
    }
  }
  
  .card-small .button-add {
    padding: 0!important;
    height: auto;
    border: 0;
    font-size: 30px!important;
    line-height: 30px!important;
    color: #E8E8E8;
    max-height: inherit;
    font-weight: 700;
    background-color: initial
  }
  
  .card-small .button-add:active,.card-small .button-add:focus,.card-small .button-add:hover {
    color: #c0daff
  }
  
  @media (max-width: 767px) {
    .card-small .button-add {
        margin-top:-10px;
        margin-left: 1.25rem
    }
  }
  
  .card-small .h-52 {
    height: 52px
  }
  
  .card-small .button-remove {
    color: #f23a58;
    height: auto;
    border: 0;
    font-size: 20px;
    padding: 0;
    background-color: initial
  }
  
  .card-small .button-remove:active,.card-small .button-remove:focus,.card-small .button-remove:hover {
    color: #f4526c
  }
  
  .dropdown>.button,.dropdown>button {
    height: 52px;
    padding-top: 0;
    max-height: 52px;
    padding-bottom: 0;
    outline: none!important;
    box-shadow: none!important;
    border-radius: 60px;
    border-color: transparent!important;
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center;
    color: white;
    background: linear-gradient(89.97deg, rgb(234, 137, 22) 0.03%, rgb(235, 106, 33) 94.43%);
  }
  
  .dropdown .dropdown-toggle:after {
    margin-left: .5em;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    border-color: currentcolor currentcolor #fff #fff;
    border-style: none none solid solid;
    border-width: 0 0 2px 2px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: all .3s ease
  }
  
  .dropdown.show>.button,.dropdown.show>button {
    color: #218cc8;
    background-color: #E8E8E8
  }
  
  .dropdown.show .dropdown-toggle:after {
    top: 3px;
    border-color: #218cc8;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
  }
  
  .dropdown .size-28 {
    margin-right: 0
  }
  
  .dropdown .count {
    font-size: 1.5rem;
    margin-left: .3125rem;
    font-weight: 700
  }
  
  .dropdown-menu {
    width: 100%;
    min-width: 200px;
    padding: 10px!important;
    background-color: #E8E8E8;
    border-radius: 10px!important
  }
  
  .dropdown-menu .size-28 {
    margin-right: 0;
    background: white;
    border-radius: 22px;
  }
  .size-28, .bg-28 {
    background: white;
    border-radius: 22px;
  }
  
  .dropdown-menu button {
    display: -moz-flex;
    display: flex;
    font-size: 1rem;
    padding: .75rem;
    font-weight: 500;
    border-radius: 40px;
    -moz-align-items: center;
    align-items: center;
    transition: all .3s ease;
    color: white;
  }
  
  .dropdown-menu button:active,.dropdown-menu button:focus,.dropdown-menu button:hover {
    color: #fff;
    background-color: rgb(33, 34, 38)
  }
  
  .card-small .dropdown>.button,.card-small .dropdown>button {
    height: 56px;
    max-height: 56px
  }
  
  .what-new .w-75 {
    width: 100%;
    max-width: 75%
  }
  
  @media (max-width: 767px) {
    .what-new .w-75 {
        width:100%!important;
        max-width: 100%!important
    }
  }
  
  .what-new-wrap {
    position: relative;
    padding: 1.25rem 3.125rem 1.5rem 10.625rem
  }
  
  @media (max-width: 767px) {
    .what-new-wrap {
        padding:2.5rem
    }
  }
  
  .what-new-wrap:before {
    top: 0;
    left: 50px;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgb(33, 34, 38);
    max-width: calc(100% - 50px);
    border-radius: 15px 100px 15px 100px
  }
  
  @media (max-width: 767px) {
    .what-new-wrap:before {
        left:0;
        max-width: 100%
    }
  }
  
  .what-new-wrap .description {
    text-align: left
  }
  
  @media (max-width: 767px) {
    .what-new-wrap .description {
        text-align:center
    }
  }
  
  .what-new-wrap h4 {
    font-size: 1.25rem;
    font-weight: 900!important
  }
  
  @media (max-width: 767px) {
    .what-new-wrap p {
        font-size:16px
    }
  }
  
  .what-new-wrap .icon-138 {
    top: 0;
    left: -160px;
    position: absolute
  }
  
  @media (max-width: 767px) {
    .what-new-wrap .icon-138 {
        left:50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%)
    }
  }
  
  .what-new .odd {
    -moz-justify-content: flex-start;
    justify-content: flex-start
  }
  
  .what-new .even {
    -moz-justify-content: flex-end;
    justify-content: flex-end
  }
  
  .what-new .even .what-new-wrap {
    padding: 1.25rem 10.625rem 1.5rem 3.125rem
  }
  
  @media (max-width: 767px) {
    .what-new .even .what-new-wrap {
        padding:2.5rem
    }
  }
  
  .what-new .even .what-new-wrap:before {
    left: auto;
    right: 50px
  }
  
  @media (max-width: 767px) {
    .what-new .even .what-new-wrap:before {
        left:0;
        right: 0;
        max-width: 100%
    }
  }
  
  .what-new .even .what-new-wrap .description {
    text-align: right
  }
  
  @media (max-width: 767px) {
    .what-new .even .what-new-wrap .description {
        text-align:center
    }
  }
  
  .what-new .even .icon-138 {
    left: auto;
    right: -160px
  }
  
  @media (max-width: 767px) {
    .what-new .even .icon-138 {
        right:50%;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%)
    }
  }
  
  .mb-25 {
    margin-bottom: 1.5rem
  }
  
  .size-100 {
    display: block;
    width: 75px;
    height: 75px
  }
  
  .size-14 {
    width: 14px;
    height: 14px
  }
  
  .size-24 {
    width: 24px;
    height: 24px
  }
  
  .link-view {
    color: #218cc8;
    font-weight: 700;
    border-bottom: 1px solid transparent;
    transition: all .3s ease
  }
  
  .link-view:after {
    top: 4px;
    right: -20px;
    content: " ";
    position: absolute;
    width: 14px;
    height: 14px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='%23218CC8'%3E%3Cpath d='M30 3v9a1 1 0 01-2 0V5.414L16.707 16.707a1 1 0 01-1.414-1.414L26.586 4H20a1 1 0 010-2h9a1 1 0 011 1zm-1 12a1 1 0 00-1 1A12 12 0 1116 4a1 1 0 000-2 14 14 0 1014 14 1 1 0 00-1-1z'/%3E%3C/svg%3E");
    background-size: 14px auto
  }
  
  .link-view:active,.link-view:focus,.link-view:hover {
    color: #218cc8;
    border-bottom: 1px solid #218cc8
  }
  
  .external-link .link-view {
    font-weight: 500
  }
  
  .Toastify__toast-container--top-right .Toastify__toast-body {
    padding: 15px 10px!important;
    align-items: flex-start!important;
    font-family: "Montserrat",sans-serif!important
  }
  
  .Toastify__toast-container--top-right .Toastify__toast-icon {
    z-index: 2;
    overflow: hidden;
    position: relative;
    width: 20px;
    height: 20px;
    top: 2px!important
  }
  
  .Toastify__toast-container--top-right .Toastify__toast-icon:before {
    top: 1px;
    left: 1px;
    z-index: -1;
    content: " ";
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 100%
  }
  
  .Toastify__toast-container--top-right .Toastify__toast--success .Toastify__progress-bar {
    background: linear-gradient(90deg,#05c31b,#0ecff8,#fcba08)
  }
  
  .Toastify__toast-container--top-right .Toastify__toast--error .Toastify__progress-bar {
    background: linear-gradient(90deg,#d5012c,#e34829,#d5012c)
  }
  
  .Toastify__toast-container--top-right .Toastify__close-button {
    margin-top: 12px!important;
    margin-right: 12px!important
  }
  
  .Toastify__toast-container--top-right {
    top: 110px!important
  }
  
  @media (max-width: 767px) {
    .Toastify__toast-container--top-right {
        top:60px!important
    }
  }
  
  .web3modal-modal-lightbox {
    z-index: 1!important
  }
  
  .web3modal-modal-card {
    font-family: "Montserrat",sans-serif!important
  }
  
  .web3modal-modal-card .web3modal-provider-wrapper {
    border-width: 2px!important
  }
  
  .web3modal-modal-card .web3modal-provider-wrapper:first-child {
    border-radius: 12px 12px 0 0!important
  }
  
  .web3modal-modal-card .web3modal-provider-wrapper:last-child {
    margin-top: -2px;
    border-radius: 0 0 12px 12px!important
  }
  
  .web3modal-modal-card .web3modal-provider-wrapper:only-child {
    border-radius: 12px!important
  }
  
  div[style="user-select: none;"] {
    z-index: 10!important
  }
  
  form .error-block {
    color: #fff;
    display: inline-block;
    margin-top: .5rem;
    font-size: .6875rem;
    line-height: .825rem;
    line-height: 1.2;
    padding: .1875rem .625rem;
    background-color: #f23a58;
    border-radius: 20px
  }
  
  .loader-block {
    width: 100%;
    min-height: 44px
  }
  
  .badge-xs {
    height: auto;
    font-size: .75rem;
    line-height: .9rem;
    padding: 5px 12px!important;
    font-weight: 500!important;
    cursor: pointer;
  }
  
  .badge-rounded {
    border-radius: 50px!important
  }
  
  .badge.bg-info {
    /* background-color: #27acde!important; */
    color: white;
    background: linear-gradient(89.97deg, rgb(234, 137, 22) 0.03%, rgb(235, 106, 33) 94.43%);
  }
  
  .node-tier-block {
    position: relative;
    padding: .625rem 0 2.5rem 2.1875rem
  }
  
  @media (min-width: 768px) {
    .node-tier-block-sm {
        padding:.625rem 0 2.5rem 2.1875rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .node-tier-block-sm {
        padding:1.25rem 0 1.25rem 1.5625rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .node-tier-block-sm {
        padding:1.25rem 0 1.25rem 1.875rem
    }
  }
  
  .node-tier-block:before {
    top: 0;
    width: 2px;
    right: -10px;
    height: 100%;
    content: " ";
    position: absolute;
    background-color: #DD8837
  }
  
  @media (max-width: 767px) {
    .node-tier-block:before {
        left:0;
        right: 0;
        top: auto;
        width: 100%;
        height: 2px;
        bottom: 15px
    }
  }
  
  .node-tier [class^=col-]:last-child .node-tier-block:before,.node-tier [class^=col-]:last-of-type .node-tier-block:before {
    display: none
  }
  
  .tier-number {
    width: 90px;
    display: -moz-flex;
    display: flex;
    -moz-align-items: flex-end;
    align-items: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical
  }
  
  .tier-number .number {
    font-size: 40px;
    font-weight: 700!important
  }
  
  .tier-number .size-90 {
    width: 90px;
    height: 90px;
    margin-top: .625what-newrem
  }
  
  .tier-details {
    height: 100%;
    display: -moz-flex;
    display: flex;
    padding-left: 1.5625rem;
    -moz-align-items: flex-start;
    align-items: flex-start;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical
  }
  
  @media (min-width: 768px) {
    .tier-details-sm {
        padding-left:1.5625rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .tier-details-sm {
        padding-left:1.5625rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .tier-details-sm {
        padding-left:1.875rem
    }
  }
  
  .tier-details h2 {
    top: -1px;
    font-size: 40px;
    line-height: 40px;
    position: relative;
    font-weight: 900!important
  }
  
  @media (max-width: 767px) {
    .tier-details h2 {
        font-size:32px;
        line-height: 32px
    }
  }
  
  .tier-details .badge {
    font-weight: 700!important;
    margin-bottom: .375rem
  }
  
  .booster-box {
    width: 100%
  }
  
  @media (max-width: 767px) {
    .booster-box {
        margin-bottom:2.8125rem
    }
  }
  
  .booster-box-wrap {
    padding: .9375rem;
    border: 1px solid #fff;
    background-color: rgb(33, 34, 38);
    border-radius: .75rem
  }
  
  @media (min-width: 768px) {
    .booster-box-wrap-sm {
        padding:1.875rem 1.25rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .booster-box-wrap-sm {
        padding:1.875rem 1.5625rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .booster-box-wrap-sm {
        padding:1.875rem 2.5rem
    }
  }
  
  .booster-box-wrap .tier-options {
    width: 100%;
    height: 100%;
    max-width: 200px;
    padding: .625rem;
    background-color: #000;
    border-radius: 10px
  }
  
  @media (min-width: 768px) {
    .booster-box-wrap .tier-options-sm {
        width:100%;
        height: 100%
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .booster-box-wrap .tier-options-sm {
        width:100%;
        height: 100%
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .booster-box-wrap .tier-options-sm {
        width:260px;
        height: 260px;
        max-width: 260px
    }
  }
  
  .booster-box-wrap .tier-options img {
    width: 100%;
    height: 100%
  }
  
  .booster-box-wrap .button {
    height: 52px;
    font-size: 18px;
    line-height: 1.25;
    max-height: 52px;
    min-width: 200px
  }
  
  @media screen and (min-width: 768px) and (max-width:991px) {
    .booster-box-wrap .button {
        width:100%;
        font-size: 17px;
        min-width: inherit;
        max-width: inherit
    }
  }
  
  .booster-box-wrap .button.is-loading {
    padding-right: 60px!important
  }
  
  .booster-box .off-white {
    color: #f2f2f2;
    line-height: 1.75
  }
  
  .booster-box .nft-spinner {
    width: auto;
    display: -moz-inline-flex;
    display: inline-flex;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center
  }
  
  .booster-box .nft-spinner .btn-minus,.booster-box .nft-spinner .btn-plus {
    color: #fff;
    border: 0;
    font-size: 24px;
    width: 32px;
    height: 32px;
    background-color: initial
  }
  
  .booster-box .nft-spinner .nft-number {
    color: #fff;
    font-size: 24px;
    padding: .25rem .625rem
  }
  
  .nft-box {
    width: 100%
  }
  
  @media (max-width: 767px) {
    .nft-box {
        margin-bottom:2.8125rem
    }
  }
  
  .nft-box-wrap {
    padding: 0;
    border: 1px solid #fff;
    background-color: #000;
    border-radius: .75rem
  }
  
  .nft-box-wrap .tier-options {
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 200px;
    padding: 0;
    background-color: #000;
    border-radius: 10px 10px 0 0
  }
  
  @media (min-width: 768px) {
    .nft-box-wrap .tier-options-sm {
        width:100%;
        height: 100%
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .nft-box-wrap .tier-options-sm {
        width:100%;
        height: 100%
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .nft-box-wrap .tier-options-sm {
        width:260px;
        height: 260px;
        max-width: 260px
    }
  }
  
  .nft-box-wrap .tier-options img {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0
  }
  
  .nft-box-footer {
    color: rgb(33, 34, 38);
    padding: .9375rem;
    background-color: #E8E8E8;
    border-radius: 0 0 .625rem .625rem
  }
  
  @media (min-width: 768px) {
    .nft-box-footer-sm {
        padding:.9375rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .nft-box-footer-sm {
        padding:1.25rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .nft-box-footer-sm {
        padding:1.25rem
    }
  }
  
  .nft-icon-list .nft-icon-list-item {
    border: 0;
    overflow: hidden;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    margin: 0 .3125rem .3125rem 0;
    background-color: #fff;
    -ms-box-shadow: 2px 2px 4px rgba(0,0,0,.125);
    box-shadow: 2px 2px 4px rgba(0,0,0,.125)
  }
  
  .nft-icon-list .nft-icon-list-item img {
    width: 38px;
    height: 38px;
    border-radius: 50%
  }
  
  .nft-icon-list.sm .nft-icon-list-item {
    width: 34px;
    height: 34px;
    margin-bottom: 0
  }
  
  .nft-icon-list.sm .nft-icon-list-item img {
    width: 30px;
    height: 30px
  }
  
  @media (max-width: 540px) {
    .sm\:flex-coll {
        -moz-flex-direction:column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  }
  
  @media (max-width: 767px) {
    body,html {
        overflow-x:hidden!important
    }
  
    .sm\:flex-row {
        -moz-flex-direction: row;
        flex-direction: row;
        -ms-box-orient: horizontal;
        box-orient: horizontal
    }
  
    .sm\:flex-col {
        -moz-flex-direction: column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  
    .sm\:align-start {
        align-items: flex-start!important
    }
  
    .sm\:justify-start {
        justify-content: flex-start!important
    }
  
    .sm\:mmb-1 {
        margin-bottom: .625rem!important
    }
  
    .sm\:mmb-2 {
        margin-bottom: 1rem!important
    }
  
    .sm\:mmb-3 {
        margin-bottom: 1.5rem!important
    }
  
    .sm\:mmb-5 {
        margin-bottom: 2rem!important
    }
  
    .sm\:mmb-6 {
        margin-bottom: 3rem!important
    }
  
    .sm\:mml-0 {
        margin-left: 0!important
    }
  
    .sm\:mmt-0 {
        margin-top: 0!important
    }
  
    .sm\:mmt-5 {
        margin-top: 1.5rem!important
    }
  
    .sm\:mmt-6 {
        margin-top: 3.5rem!important
    }
  
    .sm\:float-center,.sm\:text-center {
        float: none!important;
        text-align: center!important;
        justify-content: center!important
    }
  
    .sm\:pt-0 {
        padding-top: 0!important
    }
  
    .table .badge,.table button {
        margin-bottom: 3px
    }
  
    .sm\:pr-2 {
        padding-right: 40px!important
    }
  
    .card-small .mobile-row-delete {
        right: 0;
        left: auto;
        bottom: 1px;
        position: absolute;
        width: auto!important;
        max-width: inherit!important
    }
  
    .dynamic-row {
        margin-bottom: 3rem!important
    }
  
    .dynamic-row:last-child {
        margin-bottom: 0!important
    }
  
    .add-node-row:first-child .w-20,.add-node-row:first-of-type .w-20 {
        padding-right: 0!important
    }
  
    .unclaimed-bonus {
        align-items: center
    }
  
    .md\:flex-col {
        -moz-flex-direction: column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  
    .md\:ml-0px {
        margin-left: 0!important
    }
  
    .md\:nowrap {
        white-space: nowrap
    }
  
    .claim-button-info {
        margin-top: 20px
    }
  
    .claim-button-info .button {
        min-width: 160px
    }
  
    .box-rounded .table .badge,.box-rounded .table .button {
        display: -moz-inline-flex;
        display: inline-flex;
        -moz-align-items: center;
        align-items: center
    }
  
    .box-rounded .table .button {
        min-height: 32px;
        max-height: 32px
    }
  
    .node-tier-block {
        margin-left: auto;
        margin-right: auto;
        display: flex!important;
        padding-left: 0!important;
        justify-content: center!important
    }
  }
  
  @media screen and (min-width: 577px) and (max-width:767px) {
    .container {
        max-width:96%!important
    }
  }
  
  @media (max-width: 991px) {
    .button {
        min-height:50px;
        max-height: 52px
    }
  }
  
  @media screen and (min-width: 768px) and (max-width:991px) {
    .section .h3 {
        font-size:12px
    }
  
    .section .fs-50 {
        font-size: 20px!important;
        min-height: 65px!important;
        padding: .9375rem .5rem!important
    }
  
    .node-tier-block.last:before {
        display: none!important
    }
  
    .claim-button-info {
        margin-top: 20px
    }
  
    .claim-button-info .button {
        min-width: 160px
    }
  
    .box-rounded .table .badge,.box-rounded .table .button {
        display: -moz-inline-flex;
        display: inline-flex;
        -moz-align-items: center;
        align-items: center
    }
  
    .box-rounded .table .button {
        min-height: 32px;
        max-height: 32px
    }
  
    .node-tier-block {
        margin-left: auto;
        margin-right: auto;
        display: flex!important;
        padding-left: 0!important;
        justify-content: center!important
    }
  
    .main-podium .dropdown-menu .unclaimed-bonus .box-count.dropdown-item,.unclaimed-bonus .box-count.w-full,.unclaimed-bonus .main-podium .dropdown-menu .box-count.dropdown-item {
        max-width: 60%!important;
        margin-bottom: 0!important
    }
  
    .unclaimed-bonus .claim-button-info {
        margin-top: 0!important;
        min-width: 180px!important
    }
  
    .unclaimed-bonus .claim-button-info .button-info {
        margin-left: 0!important
    }
  }
  
  @media screen and (min-width: 992px) and (max-width:1099px) {
    .section .h3,.section h3 {
        font-size:15px
    }
  
    .section .h3.pl-4,.section h3.pl-4 {
        padding-left: 10px!important
    }
  
    .section .fs-50 {
        font-size: 24px!important;
        min-height: 65px!important;
        padding: .9375rem .5rem!important
    }
  }
  
  @media screen and (min-width: 1100px) and (max-width:1200px) {
    .section .h3,.section h3 {
        font-size:18px
    }
  
    .section .h3.pl-4,.section h3.pl-4 {
        padding-left: 10px!important
    }
  
    .section .fs-50 {
        font-size: 24px!important;
        min-height: 65px!important;
        padding: .9375rem .5rem!important
    }
  }
  
  @media screen and (min-width: 768px) and (max-width:1199px) {
    .card-small .button {
        font-size:.875rem;
        line-height: 1.05rem;
        padding: .75rem
    }
  
    .card-small .dropdown .count {
        font-size: 1.125rem
    }
  
    .size-28 {
        width: 20px;
        height: 20px
    }
  
    .section .box.p-4 {
        padding: 30px 25px 25px!important
    }
  
    .section .box-count h6 {
        font-size: 14px
    }
  
    .section .fs-30 {
        font-size: 20px!important
    }
  
    .section .size-24 {
        width: 18px;
        height: 18px
    }
  }
  
  @media (max-width: 1300px) {
    .table-responsive {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
  
    .table-responsive .table td,.table-responsive .table th {
        white-space: nowrap
    }
  }
  
  .summary-table tbody tr td:first-child,.summary-table tfoot tr td:first-child,.text-left,.text-start {
    text-align: left!important
  }
  
  .summary-table tbody tr td:last-child,.summary-table tfoot tr td:last-child,.text-end,.text-right {
    text-align: right!important
  }
  
  .summary-table tbody tr td,.summary-table tfoot tr td {
    color: #E8E8E8;
    padding: .9375rem
  }
  
  .summary-table tbody tr:nth-child(odd) {
    background-color: rgba(217,233,255,.05)
  }
  
  .summary-table tbody tr:nth-child(2n) {
    background-color: rgba(0,0,0,.33)
  }
  
  .summary-table tfoot {
    background-color: #000;
    border-color: rgba(39,172,222,.5)!important
  }
  
  .summary-table tfoot td {
    font-weight: 700
  }
  
  .fs-16,.how-it-works .description p,.what-new-wrap p {
    font-size: 1rem;
    line-height: 1.2rem;
    line-height: 1.5
  }
  
  .fs-18 {
    font-size: 1.125rem;
    line-height: 1.35rem;
    line-height: 1.5
  }
  
  .align-center {
    -moz-align-items: center;
    align-items: center
  }
  
  .main-content {
    width: 100%;
    max-width: 800px
  }
  
  .main-content-wrap {
    padding: 1.875rem 0 0
  }
  
  @media (min-width: 768px) {
    .main-content-wrap-sm {
        padding:2.1875rem 0 0
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .main-content-wrap-sm {
        padding:2.5rem 0 0
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .main-content-wrap-sm {
        padding:2.8125rem 0 0
    }
  }
  
  .main-content .coin-toss {
    width: 100%;
    max-width: 225px;
    margin-bottom: -100px;
    position: relative
  }
  
  @media (min-width: 768px) {
    .main-content .coin-toss-sm {
        max-width:240px
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .main-content .coin-toss-sm {
        max-width:275px
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .main-content .coin-toss-sm {
        max-width:300px
    }
  }
  
  .main-podium-body {
    padding-top: 1.5625rem;
    border-top: 1px solid hsla(0,0%,100%,.1)
  }
  
  .main-podium-body .pl-md-2 {
    padding-left: 10px!important
  }
  
  @media (max-width: 767px) {
    .main-podium-body .pl-md-2 {
        padding-left:0!important
    }
  }
  
  .main-podium-body .input-group button,.main-podium-body .input-group input {
    height: 50px
  }
  
  @media (max-width: 767px) {
    .main-podium-body .input-group .btn-group {
        width:140px;
        max-width: 140px
    }
  }
  
  .main-podium-body .btn {
    font-size: 15px!important;
    padding-left: 20px;
    padding-right: 20px;
    outline: none!important;
    box-shadow: none!important
  }
  
  @media (max-width: 576px) {
    .main-podium-body .btn.dropdown-toggle {
        font-size:14px!important;
        padding-left: 15px!important;
        padding-right: 15px!important
    }
  }
  
  .main-podium-body .btn-primary {
    border-color: #18181B;
    background-color: #18181B
  }
  
  .main-podium-body .btn-primary:active,.main-podium-body .btn-primary:focus,.main-podium-body .btn-primary:hover {
    border-color: #00050d;
    background-color: #00050d
  }
  
  .main-podium-body .show>.btn-primary.dropdown-toggle {
    color: #fff;
    border-color: #00050d;
    background-color: #00050d
  }
  
  .main-podium-body .btn-group {
    min-width: 125px
  }
  
  @media (max-width: 576px) {
    .main-podium-body .btn-group {
        min-width:100px
    }
  }
  
  .main-podium-body .btn-group .btn {
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0!important
  }
  
  .main-podium-body .btn-group .btn:active,.main-podium-body .btn-group .btn:focus,.main-podium-body .btn-group .btn:hover {
    background-color: #18181B!important
  }
  
  .main-podium-body .form-control {
    outline: none;
    color: #000;
    box-shadow: none;
    border-width: 0;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.35rem;
    border-color: #18181B;
    background-color: #001b40;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
  }
  
  .main-podium-body .form-control:focus {
    background-color: #002559
  }
  
  .main-podium-body .btn-lg,.main-podium-body .btn-max {
    width: 100%;
    height: 60px;
    color: #fff
  }
  
  .main-podium-body .btn-lg.is-loading,.main-podium-body .btn-max.is-loading {
    padding-right: 35px!important
  }
  
  .main-podium-body .btn-lg.is-loading:after,.main-podium-body .btn-max.is-loading:after {
    border-top: 2px solid #E8E8E8!important
  }
  
  .main-podium .dropdown-menu {
    margin-top: 0;
    border: 2px solid #18181B;
    -ms-box-shadow: 0 10px 20px 0 rgba(0,0,0,.25);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.25)
  }
  
  .main-podium .dropdown-menu .dropdown-item {
    font-size: 14px;
    max-height: 45px!important;
    font-weight: 500;
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center;
    margin-bottom: .0625rem
  }
  
  .main-podium .dropdown-menu .dropdown-item:last-child {
    margin-bottom: 0
  }
  
  .main-podium .dropdown-menu .dropdown-item.is-selected,.main-podium .dropdown-menu .dropdown-item:active,.main-podium .dropdown-menu .dropdown-item:focus,.main-podium .dropdown-menu .dropdown-item:hover {
    background-color: #18181B
  }
  
  .coin-head-tail {
    color: #bacbe4;
    padding: 0 1.875rem 1.25rem;
    border-bottom: 1px solid rgba(0,0,0,.75)
  }
  
  @media (min-width: 768px) {
    .coin-head-tail-sm {
        padding:0 2.5rem 1.5625rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .coin-head-tail-sm {
        padding:0 3.125rem 1.875rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .coin-head-tail-sm {
        max-width:90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0 3.75rem 1.875rem
    }
  }
  
  .coin-head-tail .coin-title {
    color: #bacbe4;
    font-size: 1.25rem;
    line-height: 1.5rem;
    line-height: 1.25;
    text-align: center
  }
  
  .coin-head-tail .fw-medium,.coin-head-tail [data-balloon]:after {
    font-weight: 700
  }
  
  .swap-page-dropdown {
    height: 60px;
    padding: 5px;
    background-color: #18181B
  }
  
  @media (min-width: 768px) {
    .swap-page-dropdown {
        flex-wrap:nowrap!important
    }
  }
  
  .swap-page-dropdown .btn-group,.swap-page-dropdown .form-control,.swap-page-dropdown .h-56 {
    height: 50px;
    max-height: 50px
  }
  
  .rounded-xs {
    border-radius: 5px
  }
  
  .main-podium .dropdown-menu,.main-podium .dropdown-menu .dropdown-item,.rounded-sm {
    border-radius: 10px
  }
  
  .rounded-md {
    border-radius: 15px
  }
  
  .rounded-lg {
    border-radius: 20px
  }
  
  .rounded-xl {
    border-radius: 30px
  }
  
  .rounded-full {
    border-radius: 100%
  }
  
  .coin-size-25,.coin-size-80 {
    flex-shrink: 0
  }
  
  .coin-size-25 {
    width: 25px;
    height: 25px
  }
  
  .coin-size-80 {
    width: 80px;
    height: 80px;
    flex-shrink: 0
  }
  
  @media (min-width: 768px) {
    .coin-size-80-sm {
        width:70px;
        height: 70px
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .coin-size-80-sm {
        width:80px;
        height: 80px
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:1200px) {
    .coin-size-80-sm {
        width:90px;
        height: 90px
    }
  }
  
  .coin-size-80.is-disabled {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%)
  }
  
  .mb-2 {
    margin-bottom: 15px!important
  }
  
  .mt-4 {
    margin-top: 35px!important
  }
  
  @media (max-width: 767px) {
    body,html {
        overflow-x:hidden!important
    }
  
    .sm\:flex-row {
        -moz-flex-direction: row;
        flex-direction: row;
        -ms-box-orient: horizontal;
        box-orient: horizontal
    }
  
    .sm\:flex-col {
        -moz-flex-direction: column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  
    .sm\:align-start {
        align-items: flex-start!important
    }
  
    .sm\:justify-start {
        justify-content: flex-start!important
    }
  
    .sm\:mmb-2 {
        margin-bottom: 1rem!important
    }
  
    .sm\:mmb-5 {
        margin-bottom: 2rem!important
    }
  
    .sm\:mml-0 {
        margin-left: 0!important
    }
  
    .sm\:mmt-0 {
        margin-top: 0!important
    }
  
    .sm\:mt-20 {
        margin-top: .75rem!important
    }
  
    .sm\:mmt-5 {
        margin-top: 1.5rem!important
    }
  
    .sm\:mmt-6 {
        margin-top: 3.5rem!important
    }
  
    .sm\:float-center,.sm\:text-center {
        float: none!important;
        text-align: center!important;
        justify-content: center!important
    }
  
    .sm\:w-100 {
        width: 100%!important;
        max-width: 100%!important
    }
  
    .sm\:w-1125 {
        width: calc(100% - 140px)!important;
        max-width: calc(100% - 140px)!important
    }
  }
  
  @media screen and (min-width: 992px) and (max-width:1099px) {
    .md\:flex-col {
        -moz-flex-direction:column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  
    .md\:ml-0px {
        margin-left: 0!important
    }
  
    .md\:nowrap {
        white-space: nowrap
    }
  }
  
  .image-cover {
    width: 100%;
    -webkit-object-fit: cover!important;
    -moz-object-fit: cover!important;
    -ms-object-fit: cover!important;
    object-fit: cover!important;
    background-repeat: no-repeat!important;
    -ms-background-size: cover!important;
    background-size: cover!important;
    background-position: 50%!important
  }
  
  .hero-overlay,.hero-slider-overlay {
    top: 0;
    left: 0;
    z-index: 1;
    content: "";
    font-size: 16px;
    display: -moz-flex;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center;
    background: transparent;
    background: linear-gradient(180deg,transparent,transparent);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr="to bottom",EndColorStr="rgba(0,0,0,0),rgba(0,0,0,0)")
  }
  
  .hero-slider {
    font-size: 0;
    border-bottom: 1px solid #bacbe4;
    /* background: url(/static/media/loading.cb56a9b9.svg) no-repeat 50%; */
    -ms-background-size: 32px auto;
    background-size: 32px auto;
    position: relative
  }
  
  .hero-slider .image-cover {
    -webkit-object-fit: cover!important;
    -moz-object-fit: cover!important;
    -ms-object-fit: cover!important;
    object-fit: cover!important
  }
  
  .hero-slider .slides,.hero-slider .slides li {
    margin: 0;
    padding: 0
  }
  
  .hero-slider {
    width: 100%
  }
  
  .hero-slider-overlay {
    pointer-events: none;
    display: none!important
  }
  
  @media (max-width: 767px) {
    .hero-slider-overlay {
        -moz-align-items:flex-end;
        align-items: flex-end;
        padding-bottom: 8.125rem;
        display: flex!important
    }
  }
  
  .hero-slider-wrapper {
    max-width: 100%;
    text-align: right
  }
  
  .hero-slider-wrapper .heading {
    color: #E8E8E8;
    font-size: 1.875rem;
    line-height: 2.25rem;
    line-height: 1.35;
    letter-spacing: -.1rem;
    margin-bottom: .625rem;
    font-family: "Montserrat",sans-serif;
    font-weight: 100!important
  }
  
  .hero-slider-wrapper .heading strong {
    font-size: 2.8125rem;
    line-height: 3.375rem;
    font-weight: 900!important
  }
  
  @media (min-width: 576px) {
    .hero-slider-wrapper .heading-xs {
        font-size:1.875rem;
        line-height: 2.25rem;
        line-height: 1.35
    }
  
    .hero-slider-wrapper .heading-xs strong {
        font-size: 3.125rem;
        line-height: 3.75rem;
        line-height: 1
    }
  }
  
  @media (min-width: 576px) and (min-width:768px) {
    .hero-slider-wrapper .heading-xs {
        font-size:2.1875rem;
        line-height: 2.625rem;
        line-height: 1.35;
        margin-bottom: .9375rem
    }
  
    .hero-slider-wrapper .heading-xs strong {
        font-size: 3.75rem;
        line-height: 4.5rem;
        line-height: 1
    }
  }
  
  @media (min-width: 576px) and (min-width:768px) and (min-width:992px) {
    .hero-slider-wrapper .heading-xs {
        font-size:2.5rem;
        line-height: 3rem;
        line-height: 1.25;
        margin-bottom: 1.25rem
    }
  
    .hero-slider-wrapper .heading-xs strong {
        font-size: 4.375rem;
        line-height: 5.25rem;
        line-height: 1
    }
  }
  
  @media (min-width: 576px) and (min-width:768px) and (min-width:992px) and (min-width:1200px) {
    .hero-slider-wrapper .heading-xs {
        font-size:2.625rem;
        line-height: 3.15rem;
        line-height: 1.25;
        margin-bottom: 1.5625rem
    }
  
    .hero-slider-wrapper .heading-xs strong {
        font-size: 5rem;
        line-height: 6rem;
        line-height: 1
    }
  }
  
  @media (min-width: 576px) and (min-width:768px) and (min-width:992px) and (min-width:1200px) and (min-width:1500px) {
    .hero-slider-wrapper .heading-xs {
        font-size:2.875rem;
        line-height: 3.45rem;
        line-height: 1.25;
        margin-bottom: 1.5625rem
    }
  
    .hero-slider-wrapper .heading-xs strong {
        font-size: 5.625rem;
        line-height: 6.75rem;
        line-height: 1
    }
  }
  
  .hero-slider-wrapper .heading span {
    width: 100%;
    display: inline-block;
    text-align: right
  }
  
  .hero-slider-wrapper .description {
    color: #fff;
    font-size: 1rem;
    line-height: 1.2rem;
    line-height: 1.5;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    font-family: "Montserrat",sans-serif
  }
  
  @media (min-width: 576px) {
    .hero-slider-wrapper .description-xs {
        font-size:1.125rem;
        line-height: 1.35rem;
        line-height: 1.5
    }
  }
  
  @media (min-width: 576px) and (min-width:768px) {
    .hero-slider-wrapper .description-xs {
        max-width:650px;
        font-size: 1.375rem;
        line-height: 1.65rem;
        line-height: 1.5
    }
  }
  
  @media (min-width: 576px) and (min-width:768px) and (min-width:992px) {
    .hero-slider-wrapper .description-xs {
        max-width:850px;
        font-size: 1.5rem;
        line-height: 1.8rem;
        line-height: 1.5
    }
  }
  
  @media (min-width: 576px) and (min-width:768px) and (min-width:992px) and (min-width:1200px) {
    .hero-slider-wrapper .description-xs {
        max-width:850px;
        font-size: 1.625rem;
        line-height: 1.95rem;
        line-height: 1.5
    }
  }
  
  .hero-slider-wrapper .cta-button {
    width: 100%;
    text-align: center;
    padding-right: 0
  }
  
  @media (min-width: 768px) {
    .hero-slider-wrapper .cta-button-sm {
        text-align:center;
        padding-right: 0
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) {
    .hero-slider-wrapper .cta-button-sm {
        text-align:right;
        padding-right: 3.75rem
    }
  }
  
  @media (min-width: 768px) and (min-width:992px) and (min-width:992px) {
    .hero-slider-wrapper .cta-button-sm {
        text-align:right;
        padding-right: 6.25rem
    }
  }
  
  .hero-slider-wrapper .cta-button .button {
    border: 0;
    max-width: 160px
  }
  
  .hero-slider-wrapper .cta-button .button:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
  }
  
  .hero-slider-wrapper .cta-button .button.enter-app {
    white-space: nowrap;
    color: #218cc8!important;
    -moz-align-items: center;
    align-items: center;
    font-weight: 500!important
  }
  
  .hero-slider-wrapper .cta-button .button.enter-app:hover {
    color: #fff!important
  }
  
  .hero-slider-wrapper .cta-button .button.enter-app .size-20 {
    top: 1px;
    display: -moz-flex;
    display: flex;
    position: relative;
    width: 22px;
    height: 22px;
    margin-right: .3125rem
  }
  
  .hero-slider-wrapper .cta-button .button.enter-app .size-20 svg {
    width: 100%;
    height: 100%
  }
  
  @media (max-width: 767px) {
    .hero-slider-wrapper {
        text-align:center;
        pointer-events: all
    }
  
    .hero-slider-wrapper .description,.hero-slider-wrapper .heading {
        display: none
    }
  }
  
  .hero-slider .slick-arrow {
    z-index: 10;
    font-size: 0;
    position: absolute;
    width: 32px;
    height: 32px;
    border: 2px solid #E8E8E8;
    border-radius: 50%;
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center;
    transition: all .3s ease
  }
  
  .hero-slider .slick-arrow:before {
    display: none
  }
  
  .hero-slider .slick-arrow.slick-prev {
    left: 25px;
    right: auto;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M18.6 10.5l-4-3.7c-.6-.6-1.5-.8-2.3-.5-.8.2-1.4.8-1.6 1.6-.2.7.1 1.5.7 2L7 9.8c-1.3 0-2.3.9-2.3 2.1 0 1.2 1 2.1 2.3 2.1h4.5c-2.4 1.9.7 5.1 3.1 3.1l4.1-3.7c.4-.4.7-.9.7-1.5-.1-.5-.4-1-.8-1.4zm-.7 2.3l-4.1 3.7c-.5.4-1.3.4-1.7 0-.5-.4-.5-1.2 0-1.6l1.1-1c.1-.1.2-.3.1-.5s-.2-.3-.4-.3H7.1c-.7 0-1.2-.5-1.2-1.1 0-.3.1-.6.4-.8.2-.2.5-.3.9-.3H13c.2 0 .4-.2.4-.3.1-.2 0-.4-.1-.5l-1.1-1c-.5-.4-.5-1.2 0-1.6.5-.4 1.3-.4 1.7 0l4 3.7c.4.4.4 1.2 0 1.6z' fill='%23d9e9ff'/%3E%3C/svg%3E");
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
  
  .hero-slider .slick-arrow.slick-prev:hover {
    left: 35px!important
  }
  
  .hero-slider .slick-arrow.slick-next {
    left: auto;
    right: 25px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M18.6 10.5l-4-3.7c-.6-.6-1.5-.8-2.3-.5-.8.2-1.4.8-1.6 1.6-.2.7.1 1.5.7 2L7 9.8c-1.3 0-2.3.9-2.3 2.1 0 1.2 1 2.1 2.3 2.1h4.5c-2.4 1.9.7 5.1 3.1 3.1l4.1-3.7c.4-.4.7-.9.7-1.5-.1-.5-.4-1-.8-1.4zm-.7 2.3l-4.1 3.7c-.5.4-1.3.4-1.7 0-.5-.4-.5-1.2 0-1.6l1.1-1c.1-.1.2-.3.1-.5s-.2-.3-.4-.3H7.1c-.7 0-1.2-.5-1.2-1.1 0-.3.1-.6.4-.8.2-.2.5-.3.9-.3H13c.2 0 .4-.2.4-.3.1-.2 0-.4-.1-.5l-1.1-1c-.5-.4-.5-1.2 0-1.6.5-.4 1.3-.4 1.7 0l4 3.7c.4.4.4 1.2 0 1.6z' fill='%23d9e9ff'/%3E%3C/svg%3E")
  }
  
  .hero-slider .slick-arrow.slick-next:hover {
    right: 35px!important
  }
  
  .hero-slider .slick-arrow:hover {
    opacity: .5;
    filter: alpha(opacity=50)
  }
  
  .hero-slider .slick-dots {
    top: auto;
    left: auto;
    width: auto;
    right: 30px;
    bottom: 20px;
    position: absolute;
    display: flex!important;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: flex-end;
    justify-content: flex-end
  }
  
  @media (max-width: 767px) {
    .hero-slider .slick-dots {
        display:none!important
    }
  }
  
  .hero-slider .slick-dots li {
    padding: 0;
    margin: 0 0 0 3px;
    display: -moz-flex;
    display: flex;
    width: 20px;
    height: 20px
  }
  
  .hero-slider .slick-dots li button {
    font-size: 0;
    display: -moz-flex;
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    border: 2px solid rgba(217,233,255,.5)
  }
  
  .hero-slider .slick-dots li button:before {
    top: 50%;
    left: 50%;
    font-size: 0;
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: .1;
    filter: alpha(opacity=10);
    background-color: #E8E8E8;
    border-radius: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
  }
  
  .hero-slider .slick-dots li button:hover:before {
    opacity: .5;
    filter: alpha(opacity=50);
    visibility: visible;
    width: 12px;
    height: 12px
  }
  
  .hero-slider .slick-dots li.slick-active button {
    border: 2px solid #E8E8E8
  }
  
  .hero-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    width: 12px;
    height: 12px
  }
  
  .breadcrumb {
    width: auto;
    display: -moz-flex;
    display: flex;
    text-align: left;
    margin: 0;
    padding: 0;
    -moz-align-items: center;
    align-items: center;
    background-color: hsla(0,0%,100%,0)
  }
  
  .breadcrumb>li {
    text-align: left;
    margin: 0;
    font-size: 1.125rem;
    display: -moz-inline-flex;
    display: inline-flex;
    color: hsla(0,0%,100%,.75)
  }
  
  .breadcrumb>li,.breadcrumb>li>a {
    font-family: "Montserrat",sans-serif
  }
  
  .breadcrumb>li>a {
    color: #fff;
    padding: 0
  }
  
  .breadcrumb>li>a:hover {
    color: #218cc8
  }
  
  .breadcrumb>li:before {
    content: "/";
    padding: 0 .625rem
  }
  
  .breadcrumb>li:first-child:before {
    display: none
  }
  
  .o-body-container {
    margin-bottom: 1.875rem
  }
  
  .o-body-container--right-layout {
    height: 100%;
    padding-top: 1.25rem;
    border-top: 1px solid #f7f8f9
  }
  
  .o-body-container--left-layout {
    height: 100%
  }
  
  @media (min-width: 992px) {
    .o-body-container-md {
        margin-bottom:3.125rem
    }
  
    .o-body-container-md .o-body-container--left-layout,.o-body-container-md .o-body-container--right-layout {
        border-top: none;
        padding-top: 0
    }
  
    .o-body-container-md .o-body-container--right-layout {
        padding-left: 1.25rem;
        border-left: 1px solid #f7f8f9
    }
  
    .o-body-container-md .o-body-container--left-layout {
        padding-right: 1.25rem;
        border-right: 1px solid #f7f8f9
    }
  }
  
  .o-body-container--forvideo {
    margin-bottom: 0;
    background-color: #f7f8f9;
    padding-bottom: 3.125rem
  }
  
  .rowflex {
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap
  }
  
  [class*=c-item-] {
    width: 100%;
    min-height: 1px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px
  }
  
  .c-item-full {
    max-width: 100%;
    -moz-flex: 0 0 100%;
    flex: 0 0 100%
  }
  
  .c-item-half {
    max-width: 50%;
    -moz-flex: 0 0 50%;
    flex: 0 0 50%
  }
  
  .c-item-oneThird {
    max-width: 33.33333%;
    -moz-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%
  }
  
  .c-item-quarter {
    max-width: 25%;
    -moz-flex: 0 0 25%;
    flex: 0 0 25%
  }
  
  .c-item-oneFifth {
    max-width: 20%;
    -moz-flex: 0 0 20%;
    flex: 0 0 20%
  }
  
  @media (min-width: 576px) {
    .c-item-full-sm-auto {
        width:auto;
        max-width: none;
        -moz-flex: 0 0 auto;
        flex: 0 0 auto
    }
  
    .c-item-full-sm {
        max-width: 100%;
        -moz-flex: 0 0 100%;
        flex: 0 0 100%
    }
  
    .c-item-half-sm {
        max-width: 50%;
        -moz-flex: 0 0 50%;
        flex: 0 0 50%
    }
  
    .c-item-oneThird-sm {
        max-width: 33.33333%;
        -moz-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%
    }
  
    .c-item-quarter-sm {
        max-width: 25%;
        -moz-flex: 0 0 25%;
        flex: 0 0 25%
    }
  
    .c-item-oneFifth-sm {
        max-width: 20%;
        -moz-flex: 0 0 20%;
        flex: 0 0 20%
    }
  }
  
  @media (min-width: 768px) {
    .c-item-full-md-auto {
        width:auto;
        max-width: none;
        -moz-flex: 0 0 auto;
        flex: 0 0 auto
    }
  
    .c-item-full-md {
        max-width: 100%;
        -moz-flex: 0 0 100%;
        flex: 0 0 100%
    }
  
    .c-item-half-md {
        max-width: 50%;
        -moz-flex: 0 0 50%;
        flex: 0 0 50%
    }
  
    .c-item-oneThird-md {
        max-width: 33.33333%;
        -moz-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%
    }
  
    .c-item-quarter-md {
        max-width: 25%;
        -moz-flex: 0 0 25%;
        flex: 0 0 25%
    }
  
    .c-item-oneFifth-md {
        max-width: 20%;
        -moz-flex: 0 0 20%;
        flex: 0 0 20%
    }
  }
  
  @media (min-width: 992px) {
    .c-item-full-lg-auto {
        width:auto;
        max-width: none;
        -moz-flex: 0 0 auto;
        flex: 0 0 auto
    }
  
    .c-item-full-lg {
        max-width: 100%;
        -moz-flex: 0 0 100%;
        flex: 0 0 100%
    }
  
    .c-item-half-lg {
        max-width: 50%;
        -moz-flex: 0 0 50%;
        flex: 0 0 50%
    }
  
    .c-item-oneThird-lg {
        max-width: 33.33333%;
        -moz-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%
    }
  
    .c-item-quarter-lg {
        max-width: 25%;
        -moz-flex: 0 0 25%;
        flex: 0 0 25%
    }
  
    .c-item-oneFifth-lg {
        max-width: 20%;
        -moz-flex: 0 0 20%;
        flex: 0 0 20%
    }
  }
  
  @media (min-width: 1200px) {
    .c-item-full-xl-auto {
        width:auto;
        max-width: none;
        -moz-flex: 0 0 auto;
        flex: 0 0 auto
    }
  
    .c-item-full-xl {
        max-width: 100%;
        -moz-flex: 0 0 100%;
        flex: 0 0 100%
    }
  
    .c-item-half-xl {
        max-width: 50%;
        -moz-flex: 0 0 50%;
        flex: 0 0 50%
    }
  
    .c-item-oneThird-xl {
        max-width: 33.33333%;
        -moz-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%
    }
  
    .c-item-quarter-xl {
        max-width: 25%;
        -moz-flex: 0 0 25%;
        flex: 0 0 25%
    }
  
    .c-item-oneFifth-xl {
        max-width: 20%;
        -moz-flex: 0 0 20%;
        flex: 0 0 20%
    }
  }
  
  .u-sf {
    font-family: "Montserrat",sans-serif
  }
  
  .coin-head-tail .coin-title,.main-podium-body .btn,.main-podium-body .btn-group .btn,.main-podium-body .btn-lg,.main-podium-body .btn-max,.main-podium-body .btn-primary,.main-podium .dropdown-menu .dropdown-item,.modal-body h3,.navigation-list li a,.navigation-list li button,.summary-table tfoot td,.u-tags,.u-upper {
    text-transform: uppercase
  }
  
  .main-podium .dropdown-menu .dropdown-item,.w-full {
    width: 100%!important
  }
  
  .ml-1 {
    margin-left: 5px!important
  }
  
  .ml-2 {
    margin-left: 10px!important
  }
  
  .mr-1 {
    margin-right: 5px!important
  }
  
  .mr-2 {
    margin-right: 10px!important
  }
  
  .mr-3 {
    margin-right: 15px!important
  }
  
  .webapp .mr-3 {
    margin-left: 5px!important
  }
  
  .no-padding {
    padding: 0!important
  }
  
  .no-margin {
    margin: 0!important
  }
  
  .mb-7 {
    margin-bottom: 70px!important
  }
  
  @media (max-width: 767px) {
    .mb-7 {
        margin-bottom:25px!important
    }
    .header-main-logo {
        width: 60px;
        margin-top: 0;
    }
  }
  
  .pr-2 {
    padding-right: 1rem
  }
  
  .pr-3 {
    padding-right: 1.25rem
  }
  
  .w-60 {
    width: 100%;
    max-width: 60%!important
  }
  
  .w-70 {
    width: 100%;
    max-width: 70%!important
  }
  
  .w-20 {
    width: 100%;
    max-width: 20%!important
  }
  
  .w-30 {
    width: 100%;
    max-width: 30%!important
  }
  
  .u-image-covered {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%
  }
  
  .u-advertisement-section {
    text-align: center
  }
  
  .u-advertisement-section--m80 {
    margin: 80px 0
  }
  
  .u-hr {
    width: 100%;
    margin: 36px auto;
    display: -moz-flex;
    display: flex
  }
  
  .u-hr hr {
    margin: 0;
    width: 100%;
    min-height: 1px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1)
  }
  
  .booster-box .nft-spinner .btn-minus,.booster-box .nft-spinner .btn-plus,.coin-size-25,.coin-size-80,.disconnect-button,.dropdown .size-28,.header .navbar-toggler,.hero-slider-wrapper .cta-button .button.enter-app .size-20,.loader-block,.mobile-connect .wallet-connect .size-20,.nft-icon-list .nft-icon-list-item,.size-14,.size-18,.size-20,.size-20 .spinner4,.size-24,.vc {
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center
  }
  
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%
  }
  
  @media (min-width: 576px) {
    .container {
        max-width:540px
    }
  }
  
  @media (min-width: 768px) {
    .container {
        max-width:96%!important
    }
  }
  
  @media (min-width: 992px) {
    .container {
        max-width:96%!important
    }
  }
  
  @media (min-width: 1200px) {
    .container {
        max-width:1140px
    }
  }
  
  @media (min-width: 1400px) {
    .container {
        max-width:1320px
    }
  }
  
  .col-centered {
    float: none;
    margin-left: auto;
    margin-right: auto
  }
  
  .x-close {
    top: 50%;
    right: 0;
    z-index: 50;
    border: none;
    cursor: pointer;
    position: absolute;
    width: 16px;
    height: 16px;
    background: transparent;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .4s ease-in-out
  }
  
  .x-close:after,.x-close:before {
    top: 50%;
    left: -15%;
    right: -15%;
    height: 1px;
    content: " ";
    position: absolute;
    background-color: #333;
    transition: all .2s ease-in-out
  }
  
  .x-close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
  }
  
  .x-close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
  
  .x-close:hover:after,.x-close:hover:before {
    background-color: #302d75
  }
  
  .b-ads-space.vertical {
    display: -moz-flex;
    display: flex;
    padding: 0;
    margin-bottom: 1.875rem;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center
  }
  
  @media (min-width: 768px) {
    .b-ads-space.vertical-sm {
        padding:0;
        margin-bottom: 3.125rem
    }
  }
  
  .b-ads-space.horizontal {
    display: -moz-flex;
    display: flex;
    padding: 0;
    margin-bottom: 1.875rem;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center
  }
  
  @media (min-width: 768px) {
    .b-ads-space.horizontal-sm {
        padding:0;
        margin-bottom: 3.125rem
    }
  }
  
  .b-ads-space.horizontal iframe,.b-ads-space.horizontal img,.b-ads-space.horizontal object,.b-ads-space.horizontal video {
    width: 100%;
    max-width: 769px
  }
  
  /*! Don't Remove it */
  .desktop-hidden,.mobile-hidden {
    display: none!important
  }
  
  @media (min-width: 768px) {
    .desktop-hidden {
        display:inline-flex!important;
        width: 100%
    }
  }
  
  @media (max-width: 767px) {
    .mobile-hidden {
        display:inline-flex!important;
        width: 100%
    }
  
    .img-fluid {
        width: 100%
    }
  }
  
  .dropdown-menu {
    border: 0;
    padding: 1.25rem;
    border-radius: 0;
    /* background-color: #f7f8f9; */
    color: white;
    background: linear-gradient(89.97deg, rgb(234, 137, 22) 0.03%, rgb(235, 106, 33) 94.43%);
    -ms-box-shadow: 0 2px 4px 1px rgba(89,88,89,.2);
    box-shadow: 0 2px 4px 1px rgba(89,88,89,.2)
  }
  
  .dropdown-menu ul {
    margin: 0;
    padding: 0;
    list-style: none
  }
  
  .dropdown-menu ul a {
    display: block;
    color: #434343;
    opacity: .7;
    filter: alpha(opacity=70);
    font-size: .875rem;
    line-height: 1.05rem;
    line-height: 1.5;
    padding: .125rem 0;
    font-weight: 400
  }
  
  .dropdown-menu ul a:active,.dropdown-menu ul a:hover {
    color: #302d75;
    font-weight: 500
  }
  
  .dropdown-menu .divider {
    width: 100%;
    height: 1px;
    margin: 7px auto;
    display: inline-block;
    background-color: rgba(67,67,67,.5)
  }
  
  .dropdown-menu.pull-right {
    right: 0;
    left: auto
  }
  
  .tab-content>.active {
    display: block;
    opacity: 1
  }
  
  .cursor-pointer {
    cursor: pointer
  }
  
  .clamp2 {
    display: block;
    overflow: hidden;
    min-height: 2rem;
    position: relative;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    max-height: 3rem;
    text-overflow: ellipsis;
    max-height: 4.15rem!important;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical
  }
  
  .clamp2:after {
    top: 1.5rem
  }
  
  @media (max-width: 767px) {
    .clamp2 {
        max-height:3.25rem!important
    }
  }
  
  .radius-sm {
    border-radius: .5rem
  }
  
  @media (max-width: 767px) {
    .sm\:mmb-5 {
        margin-bottom:2.125rem!important
    }
  }
  
  .easing,.footer-wrap .footer-links a,.footer-wrap .footer-links a:before,.footer-wrap .footer-social-item a,.full-page-wrapper,.hero-slider .slick-dots li button,.hero-slider .slick-dots li button:before,.main-podium-body .btn,.main-podium-body .form-control,.main-podium .dropdown-menu .dropdown-item,.navigation-list li a,.navigation-list li button,body {
    transition: all .3s ease-in
  }
  
  .text-right {
    text-align: right
  }
  
  .alert-custom {
    padding: .8rem .6rem;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0
  }
  
  @media (max-width: 767px) {
    .sm\:text-right {
        text-align:right!important
    }
  }
  
  @media (max-width: 992px) {
    .md\:text-center {
        text-align:center!important
    }
  }
  
  @media (max-width: 992px) {
    .unclaimed-bonus {
        display:-moz-flex;
        display: flex
    }
  
    .unclaimed-bonus .small-box {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(50% - 10px)
    }
  
    .unclaimed-bonus .button-info {
        margin-left: 20px
    }
  }
  
  @media (max-width: 480px) {
    .unclaimed-bonus {
        -moz-flex-direction:column;
        flex-direction: column;
        -ms-box-orient: vertical;
        box-orient: vertical
    }
  
    .unclaimed-bonus .button-info {
        margin-left: 0;
        margin-top: 20px
    }
  }
  
  @media (max-width: 767px) {
    .sm\:w-30 {
        max-width:100%!important
    }
  }
  
  .nft-icon-list,.rowflex,.u-flexed {
    display: -moz-flex;
    display: flex
  }
  
  .nft-icon-list,.u-flex-wrap {
    flex-wrap: wrap
  }
  
  .u-inline-flexed {
    display: -moz-inline-flex;
    display: inline-flex
  }
  
  .u-flex-direction-column {
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-box-orient: vertical;
    box-orient: vertical
  }
  
  .u-flexed-reversed {
    order: 2
  }
  
  .u-flex-direction-row {
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-box-orient: horizontal;
    box-orient: horizontal
  }
  
  .nft-icon-list,.u-justify-start {
    -moz-justify-content: flex-start;
    justify-content: flex-start
  }
  
  .u-justify-center {
    -moz-justify-content: center;
    justify-content: center
  }
  
  .u-justify-btw {
    -moz-justify-content: space-between;
    justify-content: space-between
  }
  
  .u-justify-end {
    -moz-justify-content: flex-end;
    justify-content: flex-end
  }
  
  .u-align-start {
    -moz-align-items: flex-start;
    align-items: flex-start
  }
  
  .nft-icon-list,.u-align-center {
    -moz-align-items: center;
    align-items: center
  }
  
  .u-align-end {
    -moz-align-items: flex-end;
    align-items: flex-end
  }
  
  .u-heading--large {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: #000;
    z-index: 11
  }
  
  @media (min-width: 576px) {
    .u-heading--large-xs {
        font-size:1.75rem;
        line-height: 2.1rem
    }
  }
  
  .u-bg-white {
    background-color: #fff
  }
  
  .u-bg-offwhite {
    background-color: #f7f8f9
  }
  
  .u-page-info__title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: #000;
    z-index: 11;
    margin-bottom: 19px
  }
  
  @media (min-width: 576px) {
    .u-page-info__title-xs {
        font-size:1.75rem;
        line-height: 2.1rem
    }
  }
  
  .u-page-info__discription p {
    font-size: .8125rem;
    line-height: .975rem;
    color: #434343;
    line-height: 1.54;
    margin-bottom: 18px
  }
  
  .u-title--heading {
    color: #0ecff8;
    font-size: 1.5rem;
    line-height: 1.8rem;
    line-height: 1.15;
    font-weight: 700;
    margin-bottom: .3125rem
  }
  
  @media (min-width: 576px) {
    .u-title--heading-xs {
        font-size:2.5rem;
        line-height: 3rem
    }
  }
  
  .u-title--subheading {
    color: #000;
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-weight: 300
  }
  
  @media (min-width: 576px) {
    .u-title--subheading-xs {
        font-size:2rem;
        line-height: 2.4rem
    }
  }
  
  .u-sectionhead {
    display: -moz-flex;
    display: flex;
    -moz-justify-content: space-between;
    justify-content: space-between;
    -moz-align-items: center;
    align-items: center;
    width: 100%;
    padding-bottom: .5625rem;
    border-bottom: 1px solid rgba(67,67,67,.5)
  }
  
  .u-sectionhead__name {
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 300
  }
  
  .u-sectionhead__name--lightgrey {
    color: #434343
  }
  
  .btn,.button,a,button {
    outline: none!important
  }
  
  .c-loadmore {
    color: #0ecff8;
    font-size: 1.125rem;
    line-height: 1.35rem;
    font-weight: 700;
    transition: all .3s ease
  }
  
  .c-loadmore .icon {
    color: #434343;
    margin-right: .25rem
  }
  
  .c-loadmore:hover,.c-loadmore:hover .icon {
    color: #302d75
  }
  
  .u-tags {
    color: #434343;
    padding: .3125rem .5rem;
    font-size: .75rem;
    line-height: .9rem;
    line-height: 1.25;
    letter-spacing: .5px;
    margin-right: .3125rem;
    margin-bottom: .3125rem;
    border-radius: 2px;
    background-color: rgba(14,207,248,.2)
  }
  
  .c-sectionedit a {
    display: block;
    display: -moz-flex;
    display: flex;
    -moz-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    justify-content: center;
    font-size: .9375rem;
    line-height: 1.125rem;
    color: #434343;
    line-height: 1.4;
    transition: all .3s ease
  }
  
  .c-sectionedit a span {
    display: inline-block;
    padding-right: .5rem
  }
  
  .c-sectionedit a:hover {
    color: #302d75
  }
  
  .list-group,.list-unstyled {
    padding-left: 0!important
  }
  
  .list-unstyled ul {
    padding-top: .625rem;
    padding-left: 2.1875rem
  }
  
  .list-icon {
    list-style: none;
    padding-left: 0;
    padding-left: 0!important
  }
  
  .list-icon li {
    margin-left: 1.25rem;
    margin-bottom: .625rem
  }
  
  .list-icon li:before {
    top: 5px;
    font-size: .75rem;
    position: absolute;
    font-style: normal;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    margin-left: -1.25rem;
    -webkit-font-smoothing: antialiased;
    font-weight: 900
  }
  
  .list-icon.list-icon-arrow-circle li:before,.list-icon.list-icon-arrow li:before,.list-icon.list-icon-caret li:before,.list-icon.list-icon-check li:before {
    font-family: "Font Awesome 5 Free"
  }
  
  .list-icon.list-icon-check li:before {
    content: "\f00c"
  }
  
  .list-icon.list-icon-arrow li:before {
    content: "\f054"
  }
  
  .list-icon.list-icon-arrow-circle li:before {
    content: "\f0a9"
  }
  
  .list-icon.list-icon-caret li:before {
    content: "\f0da"
  }
  
  .innerpage--content ol,.innerpage--content ul {
    padding-top: 0;
    margin-bottom: 1.875rem
  }
  
  .innerpage--content ol ol,.innerpage--content ol ul,.innerpage--content ul ol,.innerpage--content ul ul {
    padding-top: .625rem;
    padding-left: 2.1875rem
  }
  
  .innerpage--content ol[type="1"] {
    padding-left: 30px;
    counter-reset: list
  }
  
  .innerpage--content ol[type="1"]>li {
    list-style: none;
    position: relative
  }
  
  .innerpage--content ol[type="1"]>li:before {
    width: 40px;
    left: -40px;
    text-align: right;
    position: absolute;
    padding-right: 10px;
    counter-increment: list;
    content: counter(list) ")"
  }
  
  .innerpage--content ol[type=lower-alpha] {
    padding-left: 30px;
    list-style-type: none
  }
  
  .innerpage--content ol[type=lower-alpha] li {
    counter-increment: section
  }
  
  .innerpage--content ol[type=lower-alpha] li:before {
    width: 40px;
    left: -40px;
    text-align: right;
    position: absolute;
    padding-right: 10px;
    content: counter(section,lower-alpha) ".)"
  }
  
  .bg-transparent{
    background: transparent !important;
  }