.stake{
    border: 1px solid #DD8837;
    border-radius: 6px;
    padding: 10px;
  }
  .stake .tier-options img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .stake .tier-options {
    width: 100%;
    height: 100%;
    max-width: 80px !important;
    padding: 0.625rem;
    background-color: #000;
    border-radius: 10px;
}
.stake p{
    font-size: 14px;
}

.stake .button {
    height: 40px !important;
    font-size: 16px;
    line-height: 1.25;
    max-height: 52px;
    min-width: 130px !important;
}
.fw-400{
    font-weight: 400 !important;
}